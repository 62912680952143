import React from "react";
import "./CultureCareer.css";
import Lottie from "react-lottie";
import WorkLottie from "../../../lotties/office-work.json";

function CultureCarrier ()
{
  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: WorkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "carrier-lottie-id",
      className: "carrier-lottie-id",
    },
  };

  return (
    <>
      <section className="culture-career-container container">
        <div className="row">
          <div className="culture-career-section-1 col-12">
            {/* content-1 */}
            <div className="culture-career-1 ">
              <h1 className="culture-career-1-h1">
                <span className="culture-career-span"></span>01
              </h1>
              <h2 className="culture-career-1-h2">Know Our Culture</h2>
              <h6 className="culture-career-1-h6">
                We are Black panther of black lovers.
              </h6>
            </div>
          </div>
        </div>

        <div className="culture-career-section-2">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="culture-career-div-1">
                <div className="career-lottie-div">
                  <Lottie options={lottieAnimation} />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 culture-career my-2 mt-lg-5">
              <div className="culture-career-div-2">
                <div className="culture-career-content-1">
                  <div className="culture-career-img-div">
                    <img
                      src="./Images/Career/together-011.png"
                      className="culture-career-img"
                      alt="img"
                    ></img>
                  </div>
                  <h3 className="culture-career-h3">Diversity</h3>
                  <p className="culture-career-p">
                    We are a group of talented, headstrong
                    and diligent individuals with a common
                    driving force of pursuing Excellence in
                    our work.
                  </p>
                </div>
              </div>

              <div className="culture-career-div-2">
                <div className="culture-career-content-1">
                  <div className="culture-career-img-div">
                    <img
                      src="./Images/Career/assistance-011.png"
                      className="culture-career-img"
                      alt="img"
                    ></img>
                  </div>
                  <h3 className="culture-career-h3">Inclusion</h3>
                  <p className="culture-career-p">
                    Bring your uniqueness & personality into work, and feel like a team member to produce your best!
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 culture-career my-2 mt-lg-5">
              <div className="culture-career-div-2">
                <div className="culture-career-content-1">
                  <div className="culture-career-img-div">
                    <img
                      src="./Images/Career/fair-011.png"
                      className="culture-career-img"
                      alt="img"
                    ></img>
                  </div>
                  <h3 className="culture-career-h3">Equity</h3>
                  <p className="culture-career-p">
                    Able to foster an equitable workplace by cultivating a safe environment that allows for open discussion and understanding.
                  </p>
                </div>
              </div>

              <div className="culture-career-div-2">
                <div className="culture-career-content-1">
                  <div className="culture-career-img-div">
                    <img
                      src="./Images/Career/location-011.png"
                      className="culture-career-img"
                      alt="img"
                    ></img>
                  </div>
                  <h3 className="culture-career-h3">Easy Location</h3>
                  <p className="culture-career-p">
                    Commute easily to work at your
                    convenience and enjoy compensation
                    for transport costs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CultureCarrier;
