import React from 'react';

function DmDeptService() {
  return ( 
    <>
       <section className='web-service-section container'>
        <div className='web-service-content-div px-md-0 px-sm-4 px-2'>
          <h1 className='web-service-content-1-h1'><span className="web-service-span"></span>OUR WORKING PROCESS</h1>
          <h2 className='web-service-content-1-h2'>Our advanced and easily-integrable 
capabilities addresses various business needs</h2>

          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>SEO</h4>
                <p className='service-research-p'>Attract the traffic that matters with our 
SEO services, which include local, 
e-commerce, and national SEO.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Social Media Marketing</h4>
                <p className='service-research-p'>We engage in strategized and well-planned
social media marketing to give your brand 
the advantage of increased traffic online. 
This includes Brand Management, Social 
Tone and Nature, and focusing on Social 
PPC and Monitoring as well.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Social Media Optimization</h4>
                <p className='service-research-p'>Through social media optimization tools and 
platforms, we ensure that all of your marketing 
efforts are channelized in the right direction. 
This involves Social Profile Management, Social 
Content Strategy, and increasing organic engagement.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Email Marketing</h4>
                <p className='service-research-p'>Nurture valuable leads into customers, 
and turn one-time shoppers into repeat 
customers with email.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Content Marketing</h4>
                <p className='service-research-p'>The right content marketing strategy can
do wonders for your brand, and we’re here 
to ensure that! We take care of Content 
Strategy, Content Writing, Outreach 
Campaigns, and effective Social Promotion.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Pay Per Click Management</h4>
                <p className='service-research-p'>Pay per click is an effective way of promotion 
and visibility on the internet. We offer expert 
PPC Management, PPC Strategy Implementation, 
ad copywriting, and also complete Campaign 
Optimization.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
   );
}

export default DmDeptService;