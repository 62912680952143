import React from "react";
import AiDeptDiscuss from "../Components/DeptContainer/AiDept/AiDeptDiscuss/AiDeptDiscuss";
import DmDeptAbout from "../Components/DeptContainer/DmDept/DmDeptAbout/DmDeptAbout";
import DmDeptHome from "../Components/DeptContainer/DmDept/DmDeptHome/DmDeptHome";
import DmDeptService from "../Components/DeptContainer/DmDept/DmDeptService/DmDeptService";
import DmDeptTech from "../Components/DeptContainer/DmDept/DmDeptTech/DmDeptTech";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";

function DmDeptApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
      <SEO
        title="Services for digital marketing from Cloudin Labs"
        description="Digital marketing services from Cloudin Labs can help with customised strategies that can boost growth, engagement, and ROI. Now is the time to change your business."
        url={canonicalUrl}
        status="index"
      />
      <Loading />
      <Navbar />
      <DmDeptHome />
      <DmDeptAbout />
      <DmDeptService />
      <DmDeptTech />
      <AiDeptDiscuss />
      <Footer />
    </>
  );
}

export default DmDeptApp;
