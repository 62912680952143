import React from "react";
import { Link } from "react-router-dom";
import "./Blockchain.css";

export default function Blockchain() {
  return (
    <div className="container-fluid articlebg ">
    <div className="container">
      <div className="row text-center">
      <div className="blockchain-content-1 ">
              <h1 className='blockchain-content-1-h1'><span className="service-span"></span>05</h1>
              <h2 className='blockchain-content-1-h2'>Recent Article</h2>
              <h6 className='blockchain-content-1-h6'>Discover the newest trends.</h6>
            </div>
      </div>

<div className="row mb-4">
<div class="card-group">
  <div className="col-lg-4 p-4">
    
    <div className="card h-75 mx-auto blogbox">
   
          <img
            className="cardimage"
            src="./Images/Blogs/Blockchain/BlockchainDev.jpg"
            alt="blockchainadv"
          />
       
        <p className="blogdesc">
          What Is Blockchain Development - A Guide to Getting Started
        </p>
        <Link to="/blockchain-development-guide"><button className="blogbtn">Read</button></Link>
    </div>
  </div>

  <div className="col-lg-4  p-4">
    <div className="card h-75 mx-auto blogbox ">
  
          <img
            className="blogimg"
            src="./Images/Blogs/Blockchain/BlockchainAdv.jpg"
            alt="blockchainadv"
          />
  
      
        <p className="blogdesc">
          What Are the Advantages of Using Blockchain Technology - Unlock the
          Potential of Decentralized Innovation!
        </p>
        <Link to="/advantages-blockchain-technology"><button className="blogbtn">Read</button></Link>


    </div>
  </div>

  <div className="col-lg-4 p-4">
    <div className="card h-75 mx-auto blogbox position-relative">
    
          <img
            className="blogimg"
            src="./Images/Blogs/Blockchain/LearnBlockchainTech.jpg"
            alt="blockchainadv"
          />
       
        <p className="blogdesc">
          How to learn blockchain technology - An Expert Guidance
        </p>
        <Link to="/how-learn-blockchain-technology"><button className="blogbtn ">Read</button></Link>
    </div>
    </div>
    </div>
</div>
      {/* <div className="blogbox">
        <Link to="/blockchain-guide">
          <img
            className="blogimg"
            src="./Images/Blogs/Blockchain/BlockchainDev.jpg"
            alt="blockchainadv"
          />
        </Link>
        <p className="blogdesc">
          What Is Blockchain Development - A Guide to Getting Started
        </p>
        <button className="blogbtn">
          <Link to="/blockchain-guide" className="linkstyle">
            Read
          </Link>
        </button>
      </div>
      <div className="blogbox">
        <Link to="/blockchain-advantage">
          <img
            className="blogimg"
            src="./Images/Blogs/Blockchain/BlockchainAdv.jpg"
            alt="blockchainadv"
          />
        </Link>
        <p className="blogdesc">
          What Are the Advantages of Using Blockchain Technology - Unlock the
          Potential of Decentralized Innovation!
        </p>
        <button className="blogbtn">
          <Link to="/blockchain-advantage" className="linkstyle">
            Read
          </Link>
        </button>
      </div>
      <div className="blogbox">
        <Link to="/blockchain-learn">
          <img
            className="blogimg"
            src="./Images/Blogs/Blockchain/LearnBlockchainTech.jpg"
            alt="blockchainadv"
          />
        </Link>
        <p className="blogdesc">
          How to learn blockchain technology - An Expert Guidance
        </p>
        <button className="blogbtn">
          <Link to="/blockchain-learn" className="linkstyle">
            Read
          </Link>
        </button>
      </div> */}
        </div>
    </div>
  );
}
