import React from "react";
import Lottie from "react-lottie";
import WorkLottie from "../../../../lotties/mobile service.json";
import { useNavigate } from "react-router-dom";

function MobileDeptAbout() {
  let navigate = useNavigate();

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: WorkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "career-lottie-id",
      className: "career-lottie-id",
    },
  };

  return (
    <>
      <section className="web-about-section container my-md-5 my-4">
        <div className="web-about-content-div px-md-0 px-sm-4 px-2">
          <div className="row">
            <div className="col-md-7 col-12">
              <h1 className="web-about-content-1-h1">
                <span className="web-about-span"></span>END-TO-END
              </h1>
              <h2 className="web-about-content-1-h2">
                That you can count on our Cutting-edge technology
              </h2>
              <p className="web-about-content-1-p">
                In today’s technological era, mobile app development is no more
                an option but a must-have digital strategy for every business.
                Mobile apps are the modern day communication channels of
                businesses to reach their customers directly. The demand for
                high-end android and iOS applications has escalated in the
                business sphere.
                <br />
                <br />
                We at Cloudin Labs develop innovative, stand-out yet
                user-friendly apps to drive real outcomes and results in
                business performance. From strategy, conceptualization to custom
                mobile app development, Cloudin Labs provides a comprehensive
                suite of mobile software development services to meet your
                project requirements.
              </p>

              {/*<button className='web-content-2-btn' onClick={() => navigate('/Contact')}>Hire Now</button>*/}
            </div>

            <div className="col-md-5 col-12">
              <div className="web-about-lottie">
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MobileDeptAbout;
