import React from "react";
import MobileDeptAbout from "../Components/DeptContainer/MobileDept/MobileDeptAbout/MobileDeptAbout";
import MobileDeptDiscuss from "../Components/DeptContainer/MobileDept/MobileDeptDiscuss/MobileDeptDiscuss";
import MobileDeptHome from "../Components/DeptContainer/MobileDept/MobileDeptHome/MobileDeptHome";
import MobileDeptService from "../Components/DeptContainer/MobileDept/MobileDeptService/MobileDeptService";
import MobileDeptTech from "../Components/DeptContainer/MobileDept/MobileDeptTech/MobileDeptTech";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";

function MobileDeptApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
      <SEO
        title="Expert Solutions for Android and iOS Apps"
        description=" Check out the excellent mobile app development services offered by Cloudin Labs. Utilise the potential of innovative iOS and Android app development solutions."
        url={canonicalUrl}
        status="index"
      />

      <Loading />
      <Navbar />
      <MobileDeptHome />
      <MobileDeptAbout />
      <MobileDeptService />
      <MobileDeptTech />
      <MobileDeptDiscuss />
      <Footer />
    </>
  );
}

export default MobileDeptApp;
