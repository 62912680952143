import React from "react";
import AiDeptDiscuss from "../Components/DeptContainer/AiDept/AiDeptDiscuss/AiDeptDiscuss";
import ItDeptAbout from "../Components/DeptContainer/ItDept/ItDeptAbout/ItDeptAbout";
import ItDeptHome from "../Components/DeptContainer/ItDept/ItDeptHome/ItDeptHome";
import ItDeptService from "../Components/DeptContainer/ItDept/ItDeptService/ItDeptService";
import ItDeptTech from "../Components/DeptContainer/ItDept/ItDeptTech/ItDeptTech";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";

function ItDeptApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
    <SEO
        title=" IT consulting services can be obtained by Cloudin Labs."
        description=" IT consultancy from Cloudin Labs is a powerful way to advance your business. Benefit from innovative methods for effortless integration and improved efficiency."
        url={canonicalUrl}
        status="index"
      />
      <Loading/>
      <Navbar />
      <ItDeptHome />
      <ItDeptAbout />
      <ItDeptService />
      <ItDeptTech />
      <AiDeptDiscuss />
      <Footer />
    </>
  );
}

export default ItDeptApp;
