import React from "react";
import Lottie from "react-lottie";
import WorkLottie from "../../../../lotties/ai-lottie-2.json";
import { useNavigate } from "react-router-dom";

function AiDeptAbout() {
  let navigate = useNavigate();

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: WorkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "career-lottie-id",
      className: "career-lottie-id",
    },
  };

  return (
    <>
      <section className="web-about-section container my-md-5 my-4">
        <div className="web-about-content-div px-md-0 px-sm-4 px-2">
          <div className="row">
            <div className="col-md-7 col-12">
              <h1 className="web-about-content-1-h1">
                <span className="web-about-span"></span>END-TO-END
              </h1>
              <h2 className="web-about-content-1-h2">
                That you can count on our Cutting-edge technology
              </h2>
              <p className="web-about-content-1-p">
                In today’s digital world AI play a key role in transforming
                native business operations, creating new business models and
                unleashing process improvements. Cloudin Labs simplifies AI
                adoption for business and enable organizations to deliver value
                across the customers’ journey by supporting end-to-end services
                from data management, digitization of document and images, model
                development to operationalizing models.
                <br />
                <br />
                At Cloudin Labs, we are constantly digging into finding as well
                as solutions and infusing them with the high-end AI
                capabilities. We create AI solutions that aren’t just smarter
                but are also self-aware of boosting up the operational
                efficiency and growth in an organization. Cloudin Labs empower
                users with more agile and intuitive processes and assures
                fastest time-to-value for your AI implementation.
              </p>

              {/*<button className='web-content-2-btn' onClick={() => navigate('/Contact')}>Hire Now</button>*/}
            </div>

            <div className="col-md-5 col-12">
              <div className="web-about-lottie">
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AiDeptAbout;
