import React from 'react';
import Lottie from 'react-lottie';
import WorkLottie from '../../../../lotties/dm-lottie-2.json';
import {ImArrowRight2} from 'react-icons/im'
import '../../AiDept/AiDeptTech/AiDeptTech.css';


function DmDeptTech ()
{
  
   const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"-lottie-id"
      }
  };
  return ( 
        <>
      <section className='ai-tech-section container'>
        <div className='ai-tech-content-div'>
          <div className='row'>
            <div className='col-md-6 col-12'>
              <div className='Ml-tech-lottie-div'>
                <Lottie options={lottieAnimation}
                  style={{ height: 350, width: 350 }}
                />
              </div>
            </div>

            <div className='col-md-6 col-12'>
              <div className='ai-tech-div'>
                <h1 className='ai-tech-h1'>Why we differ from other digital
marketing service providers.</h1>

                <ul className='ai-tech-ul'>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Agile Methodologies</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Efficient Campaign Management</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Reach Precise Target Audience</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Guaranteed ROI</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Performance Tracking</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Affordable Packages</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
   );
}

export default DmDeptTech;