import React from "react";
import "./AboutBegin.css";
import { useNavigate } from "react-router-dom";

function AboutBegin() {
  let navigate = useNavigate();

  return (
    <>
      <div className="about-begin-container container-fluid">
        <div className="row about-begin-section-1">
          <div className="about-begin-content-1 col-12">
            <h6 className="about-begin-h6">Let's begin</h6>
            <div className="about-begin-line"></div>
          </div>

          <div className="about-begin-section-2 row">
            <div className="col-md-8 col-sm-8 col-12 about-begin-content-2">
              <h1 className="about-begin-h1">
                Do you care about quality? We've got everything to launch and
                grow your business
              </h1>
            </div>
            <div className="col-md-4  col-sm-4 col-12 about-begin-content-2">
              <button
                className="about-begin-btn px-4 py-2"
                onClick={() => navigate("/Contact")}
              >
                Get in touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutBegin;
