import React from 'react';
import './ServiceResearch.css';


function ServiceResearch() {
  return ( 
    <>
      <div className='service-research-div-new container'>
        <div className='service-research-content'>
          {/* <h6 className='service-research-h6'>We provide meaningful and actionable data driven insights that represent the voices of a user and resonate with business objectives.</h6>*/}
          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Heuristic Evaluation</h4>
                <p className='service-research-p'>A heuristic analysis is used to identify a product’s common usability issues so that the problems can be resolved, consequently improving the user’s satisfaction.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Design Audit</h4>
                <p className='service-research-p'>A design audit is not just about correcting various elements, but also understanding the process behind them and recognising where they can be improved to enhance your brand in the future.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Usability Testing</h4>
                <p className='service-research-p'>User testing participants are in the best position to help you see where things might be confusing to a newcomer who isn’t involved with your business.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Ethnographic Research</h4>
                <p className='service-research-p'>Anthropological ethnographers often live amongst a group/society for a year or more, in order to learn about them. </p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>UX Research</h4>
                <p className='service-research-p'>We understand your business and its workings better than anyone. How can uninformed external users help you learn more? and focus on how successfully those customers navigate self-service website.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Quantitative Research</h4>
                <p className='service-research-p'>There are many quantitative research methods, and they help uncover different types of information on users. Some methods,such as A/B testing, are typically done on finished products.</p>
              </div>
            </div>

          </div>

          <div className='service-research-img-content'>
            <div className='row'>
              <div className='col-12'>
                <div className='service-bg-img-div'>
                  <img src='./Images/Services/Research_Img_Banner.jpg' className='service-bg-img' alt='img' />
                  <div className='service-bg-div'>
                    <h2 className='service-bg-h2'>We believe in innovation and that the way you get innovation is you fund research and you learn the basic facts.</h2>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
   );
}

export default ServiceResearch;
