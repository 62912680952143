import React from "react";
import "./AboutAbout.css";

function AboutAbout() {
  return (
    <>
      <div className="container-fluid about-about-container">
        <div className="row">
          {/* section -1 */}

          <div className="about-about-section-1 col-md-8 col-sm-12 col-12">
            <div className="about-about-content-1 ">
              <h1 className="about-about-content-1-h1">
                <span className="about-about-span"></span>01
              </h1>
              <h2 className="about-about-content-1-h2">About Cloudin Labs</h2>
              <p className="about-about-content-1-p">
                Cloudin Labs are a full-cycle techno-digital agency focusing on
                the latest technologies of artificial intelligence, deep
                learning, and machine learning algorithm along with engineering
                IoT solutions and helping businesses to achieve
                industry-specific solutions delivered reliably on time.
                <br />
                <br />
                In 2020 Cloudin Labs was acquired by Synth tech, a Dubai-based
                Blockchain company.
                <br />
                <br />
                Cloudin Labs is focused on providing viable, cost-effective
                Information Technology and related services to both
                international as well as domestic clients. We emphasize the
                quality of the projects we undertake rather than their quantity.
                This is very much evident from our profound long-term existence
                in today’s fast-growing IT world.
              </p>
            </div>

            <div className="about-about-content-2">
              <div className="about-about-content-2-div">
                <h1 className="about-about-content-2-h1">08+</h1>
                <p className="about-about-content-2-p">Experience</p>
              </div>

              <div className="about-about-content-2-div">
                <h1 className="about-about-content-2-h1">03+</h1>
                <p className="about-about-content-2-p">Products</p>
              </div>

              <div className="about-about-content-2-div">
                <h1 className="about-about-content-2-h1">06+</h1>
                <p className="about-about-content-2-p">Services</p>
              </div>

              <div className="about-about-content-2-div">
                <h1 className="about-about-content-2-h1">180+</h1>
                <p className="about-about-content-2-p">Clients</p>
              </div>
            </div>
          </div>

          {/* section-2 */}

          <div className="about-about-section-2 col-md-4 col-sm-12 col-12">
            <div className="about-about-content-3">
              <img
                className="about-about-img"
                src="./Images/About/baby copy.png"
                alt="about"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutAbout;
