import React from 'react'
import './WorkCase.css'

function WorkCase() {
  return ( 
    <>
      <div className="work-case-container container">
        <h1 className="work-case-title">Our case study speaks <br/>a lot about us</h1>
        <div className="work-case-row row">

          <div className="work-case-div col-sm-6 col-12">
            <div className="work-case-img-div">
              <img src="./Images/Portfolio/port-1.jpg" className="work-case-img" alt="img" />
            </div>
            <h6 className="work-case-h6">Enabling Cross-border Investment & Trading for Indian Investors.</h6>
            <p className="work-case-p">Fin Tech, UX Consulting, UX & UI Design</p>
          </div>
          <div className="work-case-div col-sm-6 col-12">
            <div className="work-case-img-div">
              <img src="./Images/Portfolio/port-2.jpg" className="work-case-img" alt="img" />
            </div>
            <h6 className="work-case-h6">Enabling Cross-border Investment & Trading for Indian Investors.</h6>
            <p className="work-case-p">Fin Tech, UX Consulting, UX & UI Design</p>
          </div>


          <div className="work-case-div col-sm-6 col-12">
            <div className="work-case-img-div">
              <img src="./Images/Portfolio/port-3.jpg" className="work-case-img" alt="img" />
            </div>
            <h6 className="work-case-h6">Enabling Cross-border Investment & Trading for Indian Investors.</h6>
            <p className="work-case-p">Fin Tech, UX Consulting, UX & UI Design</p>
          </div>
          <div className="work-case-div col-sm-6 col-12">
            <div className="work-case-img-div">
              <img src="./Images/Portfolio/port-5.jpg" className="work-case-img" alt="img" />
            </div>
            <h6 className="work-case-h6">Enabling Cross-border Investment & Trading for Indian Investors.</h6>
            <p className="work-case-p">Fin Tech, UX Consulting, UX & UI Design</p>
          </div>


        </div>
      </div>
    </>
   );
}

export default WorkCase;