import React from 'react';
import PrivacyHome from '../Components/PrivacyAndPolicy/PrivacyHome/PrivacyHome';
import PrivacyPolicy from '../Components/PrivacyAndPolicy/PrivacyPolicy/PrivacyPolicy';
import Footer from '../Footer/Footer';
import Loading from '../Loading/loading';
import Navbar from '../Navbar/Navbar';

function PpApp() {
  return ( 
    <>
      <Loading/>
      <Navbar />
      <PrivacyHome />
      <PrivacyPolicy/>
      <Footer/>
    </>
   );
}

export default PpApp;