import React from "react";
import '../../AboutContainer/AboutHome/AboutHome.css';
import { useNavigate } from 'react-router-dom';


function PrivacyHome () {
    let navigate = useNavigate();
  
    return ( 
      <>
        <div className="about-home-container container-fluid">
          <div className="about-home-div-1 row">
            <div className="about-home-img-div">
            <img src="./Images/Home/Privacy_Policies.jpg" className="about-home-img" alt="img" />
            </div>
            <div className="about-home-h1-div">
              <h1 className="about-home-h1">We're Changing the
            <br/>  Whole <span className="about-home-span">Game.</span></h1>
            </div>
            <div className="about-home-p-div">
            <p className="about-home-p" onClick={() => navigate('/')}>Home / Privacy & Policy</p>
            </div>
          </div>
        </div>
      </>
     );
}
export default PrivacyHome;