import React from 'react';
import './AboutClient.css';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper';


SwiperCore.use( [ Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectCube ] );

function AboutClient ()
{
  return (
    <>
      {/* <div className="row client-row-2">
          <div class="client-content-2 col-11">
            <Swiper
              effect="Cube"
              spaceBetween={80}
              slidesPerView={1}
              autoplay= {{delay: 3000}}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
            <SwiperSlide>
                <div className='client-logos'>
                  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-1.jpeg' className="client-logo img-fluid"/>  
                </div>
  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-2.png' className="client-logo img-fluid"/>  
                </div>
                  
                <div className="client-logo-div card">
                <img src='./Images/Client/c-3.png' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/c-4.png' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/c-5.png' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/c-7.svg' className="client-logo img-fluid"/>  
                </div>

               </div>   
              </SwiperSlide>
              
              <SwiperSlide>
                <div className='client-logos'>
                  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-8.jpeg' className="client-logo img-fluid"/>  
                </div>
  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-9.png' className="client-logo img-fluid"/>  
                </div>
                  
                <div className="client-logo-div card">
                <img src='./Images/Client/c-10.png' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/c-11.jpeg' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/c-12.svg' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/logo-1.png' className="client-logo img-fluid"/>  
                </div>

               </div>   
                </SwiperSlide>
                  
            </Swiper>
            
          </div>
  </div>*/}

      <div className="row client-row-2">
        <div className="client-content-2 col-11">
          <Swiper
            effect="Cube"
            spaceBetween={80}
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
          >
            <SwiperSlide>
              <div className='client-logos'>

                {/* <div className="client-logo-div card">
                  <img src='./Images/Client/c-1.jpeg' className="client-logo img-fluid"/>  
                </div>
  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-2.png' className="client-logo img-fluid"/>  
  </div>*/}
                <a href='https://gazo1.vercel.app/#/home' target="_blank" rel='noreferrer'>
                  <div className="client-logo-div card">
                    <img src='./Images/Client/c-7.svg' className="client-logo client-logo-new img-fluid" alt='img' />
                  </div>
                </a>

                <a href='https://acceltop.com ' target="_blank" rel='noreferrer'>
                  <div className="client-logo-div card">
                    <img src='./Images/Client/c-3.png' className="client-logo img-fluid" alt='img' />
                  </div>
                </a>

                <a href='https://www.greenbound.biz' target="_blank" rel='noreferrer'>
                  <div className="client-logo-div card">
                    <img src='./Images/Client/logo-1.png' className="client-logo img-fluid" alt='img' />
                  </div>
                </a>

                <a href='https://iquantsgraph.com' target="_blank" rel='noreferrer'>
                  <div className="client-logo-div card">
                    <img src='./Images/Client/c-5.png' className="client-logo img-fluid" alt='img' />
                  </div>
                </a>

                <a href='https://www.360immerse.in' target="_blank" rel='noreferrer'>
                  <div className="client-logo-div card">
                    <img src='./Images/Client/check.png' className="client-logo img-fluid" alt='img' />
                  </div>
                </a>


                <a href='https://www.instagram.com/sparsh_designer' target="_blank" rel='noreferrer'>
                  <div className="client-logo-div card">
                    <img src='./Images/Client/c-20.png' className="client-logo img-fluid" alt='img' />
                  </div>
                </a>


              </div>
            </SwiperSlide>

            {/*<SwiperSlide>
                <div className='client-logos'>
                  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-8.jpeg' className="client-logo img-fluid"/>  
                </div>
  
                <div className="client-logo-div card">
                  <img src='./Images/Client/c-9.png' className="client-logo img-fluid"/>  
                </div>
                  
                <div className="client-logo-div card">
                <img src='./Images/Client/c-10.png' className="client-logo img-fluid"/>  
                </div>
                
                <div className="client-logo-div card">
                <img src='./Images/Client/c-11.jpeg' className="client-logo img-fluid"/>  
                </div>
                
               

               </div>   
</SwiperSlide>*/}

          </Swiper>

        </div>
      </div>
    </>
  );
}

export default AboutClient;