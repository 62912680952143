import React from 'react';
import './CareerFun.css';

function CareerFun ()
{
  return (
    <>
      <section className='career-fun-section container'>
        <div className='row'>
          <div className="career-fun-section-1 col-12">
            {/* content-1 */}
            <div className="career-fun-1">
              <h1 className='career-fun-1-h1'><span className="career-fun-span"></span>02</h1>
              <h2 className='career-fun-1-h2'>Our Fun Culture</h2>
            </div>
          </div>
        </div>

        <div className='career-fun-image-content'>
          <div className='row'>
            <div className='col-4'>
              <div className='career-fun-img-div'>
                <img src='./Images/Career/fun-1.jpg' className='career-fun-img' alt='img'></img>
              </div>
              <div className='career-fun-img-div'>
                <img src='./Images/Career/fun-3.jpg' className='career-fun-img' alt='img'></img>
              </div>
            </div>

            <div className='col-4'>
              <div className='career-fun-img-div-1'>
                <img src='./Images/Career/fun-2.jpg' className='career-fun-img-1' alt='img'></img>
              </div>
              <div className='career-fun-img-div-1'>
                <img src='./Images/Career/fun-6.jpg' className='career-fun-img-1' alt='img'></img>
              </div>
            </div>

            <div className='col-4'>
              <div className='career-fun-img-div'>
                <img src='./Images/Career/fun-4.jpg' className='career-fun-img' alt='img'></img>
              </div>
              <div className='career-fun-img-div'>
                <img src='./Images/Career/fun-5.jpg' className='career-fun-img' alt='img'></img>
              </div>
            </div>
          </div>
        </div>

      </section>
    </>
  );
}

export default CareerFun;