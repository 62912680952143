import React from 'react';
import Lottie from 'react-lottie';
import WorkLottie from '../../../../lotties/dm-lottie-1.json';
import { useNavigate } from "react-router-dom";



function DmDeptAbout ()
{
  let navigate = useNavigate();
  
   const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };

  return ( 
    <>
      <section className='web-about-section container my-md-5 my-4'>
        <div className='web-about-content-div px-md-0 px-sm-4 px-2'>
          <div className='row'>
            <div className='col-md-7 col-12'>
              <h1 className='web-about-content-1-h1'><span className="web-about-span"></span>Our Competency</h1>
              <h2 className='web-about-content-1-h2'>The Right Choice for Digital Marketing Services</h2>
              <p className='web-about-content-1-p'>
                <h6 className='Dm-about-h6 h6 fw-bold text-dark'>Are you looking to</h6>
              Boost your brand’s presence on digital platforms? Cloudin Labs digital is one of the best online marketing service providers in all location, with an array of digital marketing services to suit all your requirements. To get your company in the top ranks, you need to opt for the best digital marketing service provider. We understand your needs, and create a custom plan and strategy just for you. With our digital marketing packages, you won’t have to worry anymore, and we promise to deliver only the best!
                <br />
                <br />
                <h6 className='Dm-about-h6  h6 fw-bold text-dark'>Cloudin Labs Digital Marketing Services</h6>
                We are an experienced digital marketing company in Coimbatore comprised of a team of highly trained professionals. We are constantly updating our skill set and R&D practices, to ensure that each of our clients benefit from efficient digital marketing services all year round.
              </p>

              {/*<button className='web-content-2-btn' onClick={() => navigate('/Contact')}>Hire Now</button>*/}
            </div>

            <div className='col-md-5 col-12'>
              <div className='ml-about-lottie mt-md-5'>
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
   );
}

export default DmDeptAbout;