import React from 'react';

function MlDeptService ()
{
  return (
    <>
      <section className='web-service-section container'>
        <div className='web-service-content-div px-md-0 px-sm-4 px-2'>
          <h1 className='web-service-content-1-h1'><span className="web-service-span"></span>SOLUTIONS UNLEASHING</h1>
          <h2 className='web-service-content-1-h2'>Machine Learning capabilities that enables organizations to increase top-line growth</h2>

          <div className='row'>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Cybersecurity</h4>
                <p className='service-research-p'>Develop an intelligent and automated
                  cyber defense system with minimum-
                  skilled cybersecurity force.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Algorithm Design</h4>
                <p className='service-research-p'>Solve problems pertaining to designing
                  or writing effective algorithms.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Data Modelling</h4>
                <p className='service-research-p'>Offer comprehensive data modelling,
                  right from hypothesizing to physical
                  implementation.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Natural Language Processing</h4>
                <p className='service-research-p'>Provide your clients with contextual, user-
                  friendly and meaningful customer service
                  around the clock.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Visual Technology</h4>
                <p className='service-research-p'>Cloudin Technologies also develops ultra-
                  modern visual apps that obtain, analyse,
                  and synthesize images and recognize
                  patterns in them.</p>
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
}

export default MlDeptService;