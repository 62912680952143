import React from 'react';
import './home.css'
import { useNavigate } from 'react-router-dom'

function Home ()
{

  let navigate = useNavigate();

  return ( 
    <>
      <div className="home-container container-fluid">
        
        {/*<img src="./Images/Home/Home-image.jpg"  className="home-image"/>*/}

      <div className="back-video-div">
        <video muted plays-inline autoplay="true" loop className="back-video">
          <source src="./Images/Home/Cloudin Website 10MB_1.mp4" type="video/mp4" className="back-video"/>
        </video>  
      </div>
        

        <div className="home-content">

          <p className="home-content-p">Creative Mind, Creative Works</p>
          <h1 className='home-content-title'>We are doing <span className='home-content-title-orange'>Digital Transformation</span></h1>

          <button className="home-content-btn" onClick={() => navigate('/Contact')}>Contact us</button>
        </div>
      </div>
    </>
   );
}

export default Home;
