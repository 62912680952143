import React from "react";
import "./CareerDescription.css";

function CareerDescription() {
  return (
    <>
      <section className="career-desc-section container mt-md-5 mt-3">
        <h1 className="h1 fw-bolder">Job Openings</h1>
        <div className="row">
          <div className="col-12">
            <div className="career-desc-content">
              <div className="career-desc-line"></div>
              <h2 className="career-desc-h2">Technology</h2>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Front End Developer - React JS 3 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking to hire a talented Front End
                        Developer developer to design, build, and maintain the
                        next generation of web applications. Your primary focus
                        will be developing high-end web applications using React
                        js Library.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Backend Developer - 2 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking for an experienced Back-end
                        Developer to join our IT Team. You should have hands-on
                        experience with programming languages and tools like
                        Laravel /CodeIgniter (or any MVC Framework), MySQL /
                        MongoDB /DynamoDB, Rest API, AWS / Digital Ocean, Jira,
                        Kubernetes, Version Controlling (Git /SVN).
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Flutter Developer - 1 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin Team is looking out for a Flutter Developer who
                        will be developing and designing product application
                        features across various cross platform devices. He/She
                        should be proficient in Dart and Flutter. Please do
                        update the same in careers pages as soon as possible.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Dot Net Developer - 2 vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is seeking to look for a .NET developer
                        responsible for building .NET applications. You should
                        have a relevant experience with MVC, Net Core, Web API,
                        and SQL Server. A commitment to collaborative
                        problem-solving, sophisticated design, and quality
                        product is essential.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12  mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        iOS Developer - 2 vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking to hire a talented IOS developer
                        to design, build, and maintain the next generation of
                        iOS applications. Your primary focus will be developing
                        high-end iOS applications for the latest Apple mobile
                        device.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12  mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Android Developer - 2 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking for an Android developer
                        responsible for the development and maintenance of
                        applications aimed at a vast number of various Android
                        devices. Strong knowledge of Kotlin & Flutter, and how
                        to deal with different screen sizes.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12  mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Blockchain Developer - 2 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is s looking for a Blockchain developer who
                        will create the next generation of NFT experiences and
                        blockchain. We are looking for a highly capable
                        blockchain developer to design and develop using
                        Solidity, Ethereum blockchain and React js{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12  mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="career-desc-content">
              <div className="career-desc-line"></div>
              <h2 className="career-desc-h2">Software Testing</h2>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Software Tester - 1 vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin Team is looking for passionate Software Testers
                        to join our team! He/she should have good knowledge of
                        the Selenium suite of Tools (Selenium IDE, Selenium RC,
                        Selenium WebDriver and Selenium Grid).
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="career-desc-content">
              <div className="career-desc-line"></div>
              <h2 className="career-desc-h2">Marketing</h2>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Software Sales Executive - 4 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking for a Software Sales Executive
                        for our organization. You should handle in-depth
                        knowledge of products and technologies, competitors, and
                        market conditions. Must have excellent communication and
                        presentation skills
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Content Writer - 2 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking for a content writer who is
                        well-versed in multiple languages (English/Tamil/Hindi)
                        to create content for blogs, articles, product
                        descriptions, social media, and the company website. You
                        should also be proficient in using various content
                        management tools
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Business Analyst - 1 Vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking for an experienced business
                        analyst to join our team. Your main responsibilities
                        will include performing detailed requirements analysis,
                        wireframe analysis, documenting processes, and
                        performing some user acceptance testing.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12  mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="career-desc-content">
              <div className="career-desc-line"></div>
              <h2 className="career-desc-h2">Human Resources</h2>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Human Resources 3-5 years - 1 vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin Team is looking for a Human Resources
                        professional who will oversee and administer the
                        company's payroll services, ensure compliance and
                        accuracy of monthly payroll activities, and ensure
                        employee benefits, including employee reimbursements
                        according to income tax rules and regulations, Form 16s,
                        and tax returns. In order to succeed in this position,
                        he/she must have the ability to build and manage
                        interpersonal relationships effectively at all levels of
                        the organization
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="career-desc-content">
              <div className="career-desc-line"></div>
              <h2 className="career-desc-h2">Designing</h2>

              <div className="career-desc-card-div">
                <div className="row">
                  <div className="col-md-9 col-12">
                    <div className="career-desc-inner-div">
                      <h3 className="career-disc-h3">
                        Animated Video Editor 3 years -1 vacancy
                      </h3>
                      <p className="career-disc-p">
                        Cloudin team is looking for Animated Video Editor should
                        have expert knowledge of modern video editing
                        techniques, be able to visualize 3-D concepts and work
                        according to a creative schedule.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-12 mt-md-0 mt-2">
                    <div className="career-desc-inner-div">
                      <h6 className="career-disc-h6">Coimbatore, Tamil Nadu</h6>
                      <h6 className="career-disc-h6-2">Full Time</h6>
                      <div className="career-disc-btn-div d-flex justify-content-end mt-md-5 mt-3">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSf2XRldHWD_QQkETtXhNGRQC4MQ7dtuYvZjyxbSjRBHfIpOpw/viewform?usp=sf_link"
                          target="_blank"
                          className="career-disc-btn"
                          rel="noreferrer"
                        >
                          Apply Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CareerDescription;
