import React from "react";
import "./Review.css";
function Review() {
  return (
    <>
      <div className="work-begin-container container-fluid mt-5">
        <div className="review-begin-section-2 row px-sm-5 px-0">
          <div className="col-md-8 col-sm-8 col-12 py-sm-5 py-4 ps-md-5 ps-4 work-begin-content-2 ">
            <h1 className="review-h1 text-white fw-bold h2">
              Viewers will be Reviewers always
            </h1>
            <p className="review-p text-white">
              We are Offering Cash Prize for Top 10 Customer Reviews{" "}
            </p>
          </div>
          <div className="col-md-4 col-sm-4 col-12 d-sm-flex align-items-center justify-content-left pb-sm-0 pb-4 ps-sm-0 ps-0 web-discuss-content-2">
            <a
              className="review-btn"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeGNrdTfsLV_-mNCexJBIW20yyC4Vppg6Cmf-44uaNTnrOnDQ/viewform?usp=sf_link"
              target="_blank"
            >
              Review Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review;
