import React from 'react';
import ServiceBegin from '../Components/ServiceContainer/ServiceBegin/ServiceBegin';
import ServiceHome from '../Components/ServiceContainer/ServiceHome/ServiceHome';
import ServiceScroll from '../Components/ServiceContainer/ServiceScroll/ServiceScroll';
import ServiceTesti from '../Components/ServiceContainer/ServiceTesti/ServiceTesti';
import Footer from '../Footer/Footer';
import Loading from '../Loading/loading';
import Navbar from '../Navbar/Navbar';
import SEO from '../seo';

function ServiceApp() {

  const canonicalUrl = window.location.href;

  return ( 
    <>
     <SEO
        title="Providing Excellent Commercial Service to Support Businesses"
        description="Growth, efficiency, and success are being unleashed. To elevate your business, explore about specialised solutions, strategic planning, and cutting-edge technologies."
        url={canonicalUrl}
        status="noindex"
      />
      <Loading/>
      <Navbar/>
      <ServiceHome />
      <ServiceScroll />
      <ServiceBegin />
      <ServiceTesti />
      <Footer/>
    </>
   );
}

export default ServiceApp;