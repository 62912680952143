import React from 'react';
import './WebDeptService.css'

function WebDeptService() {
  return ( 
    <>
      <section className='web-service-section container'>
        <div className='web-service-content-div px-md-0 px-sm-4 px-2'>
          <h1 className='web-service-content-1-h1'><span className="web-service-span"></span>OUR FULL-STACK</h1>
          <h2 className='web-service-content-1-h2'>Web application development services</h2>

          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Custom web application development</h4>
                <p className='service-research-p'>From basic landing pages to customized web development India, we offer custom web application development services.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Technology Consulting</h4>
                <p className='service-research-p'>we are focused to become your reliable IT partner with the functional know-how resources, and domain expertise.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Dedicated web application development</h4>
                <p className='service-research-p'>Reduce your overhead cost, ensure high-quality websites and offer better time to market</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Full Stack Development</h4>
                <p className='service-research-p'>From basic landing pages to customized web development India, we offer custom web application development services.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Third Party Integration</h4>
                <p className='service-research-p'>Creates APIs custom data connectors, web services, and middleware for secure integration with third-party solutions.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
   );
}

export default WebDeptService;