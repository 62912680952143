import React from 'react';
import WorkBegin from '../Components/WorkContainer/WorkBegin/WorkBegin';
import WorkCase from '../Components/WorkContainer/WorkCase/WorkCase';
import WorkHome from '../Components/WorkContainer/WorkHome/WorkHome';
import WorkTesti from '../Components/WorkContainer/WorkTestimonial/WorkTesti';

function WorkApp() {
	return (
		<div className="WorkApp">
			<loading />
			<WorkHome />
			<WorkCase />
			<WorkBegin /> 
			<WorkTesti />
		</div>
	);
}

export default WorkApp;
