import React from 'react';
import Lottie from 'react-lottie';
import WorkLottie from '../../../../lotties/ml-lottie-1.json';
import { useNavigate } from "react-router-dom";



function MlDeptAbout ()
{
  let navigate = useNavigate();
  
   const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };

  return ( 
    <>
      <section className='web-about-section container my-md-5 my-4'>
        <div className='web-about-content-div px-md-0 px-sm-4 px-2'>
          <div className='row'>
            <div className='col-md-7 col-12'>
              <h1 className='web-about-content-1-h1'><span className="web-about-span"></span>Our Competency</h1>
              <h2 className='web-about-content-1-h2'>Simplify the creation of machine learning models with powerful NLP algorithm with datasets of any size</h2>
              <p className='web-about-content-1-p'>
               Machine Learning and natural language processing entered the mainstream business, disrupting existing business models through its predictive capabilities. Over the past decade, it’s no surprise that with the volume of raw, unstructured data available today enterprise ML &NLP solutions are one of the most sought-after services
                <br />
                <br />At Cloudin Labs, we offer a broad range of machine learning algorithms with industry-leading speed and scale. The workbench and toolkit of ML & NLP simplify creation of AI model by applying automation to the data science workflow. Our comprehensive machine learning solutions untangle complexity and create your end-to-end AI solution, from the core data centre to the intelligent edge.
              </p>

              {/*<button className='web-content-2-btn' onClick={() => navigate('/Contact')}>Hire Now</button>*/}
            </div>

            <div className='col-md-5 col-12'>
              <div className='ml-about-lottie mt-md-5'>
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
   );
}

export default MlDeptAbout;