import React from "react";
import ContactDelivery from "../Components/ContactContainer/ContactDelivery/ContactDelivery";
import ContactForm from "../Components/ContactContainer/ContactForm/ContactForm";
import ContactHome from "../Components/ContactContainer/ContactHome/ContactHome";
import ContactMap from "../Components/ContactContainer/ContactMap/ContactMap";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";

function ContactApp() {
	const canonicalUrl = window.location.href;

  return (
    <div className="contact-app">
      <SEO
        title="Get in Contact with Cloudin Labs for Digital Solutions"
        description=" Contact Cloudin Labs - Your Reliable Partner for High-Level Digital Solutions and Support! At Cloudin Labs, we value queries and work hard to provide excellent support."
        url={canonicalUrl}
        status="noindex"
      />
      <Loading />
      <Navbar />
      <ContactHome />
      <ContactForm />
      <ContactDelivery />
      <ContactMap />
      <Footer />
    </div>
  );
}

export default ContactApp;
