import React from "react";
import ProductHome from "../Components/ProductContainer/ProductHome/ProductHome";
import ProductScroll from "../Components/ProductContainer/ProductScroll/ProductScroll";
import ServiceBegin from "../Components/ServiceContainer/ServiceBegin/ServiceBegin";
import ServiceTesti from "../Components/ServiceContainer/ServiceTesti/ServiceTesti";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";

function ProductApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
      <SEO
        title="Developing Innovative Digital Products"
        description=" Explore about the future of computing in the cloud to provide your company access to unrivalled efficiency, security, and expansion options."
        url={canonicalUrl}
        status="noindex"
      />
      <Loading />
      <Navbar />
      <ProductHome />
      <ProductScroll />
      <ServiceBegin />
      <ServiceTesti />
      <Footer />
    </>
  );
}

export default ProductApp;
