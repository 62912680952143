import React from 'react';

function ProductCt ()
{
  return (
    <>
      <div className='service-research-div container'>
        <div className='service-research-content'>
          <h4 className='service-research-head fw-bold mb-3 h5'>CRYPTOTRACK: CHANGING CRYPTOTRACK RELATED NEWS IN SINGLE</h4>
          <h6 className='service-research-h6'>Crypto Track is a application that allows you to know in-depth information and knowledge of Crypto-currencies.
            Crypto Track is an all-in-one digital platform featuring the current news, market trends, and education details along with
            blogs about crypto-currencies. We wanted to create a most useful digital platform for everyone who wants awareness of
            the crypto market and benefits from it. This app also enables you to share and save the news on your mobile phone.

          </h6>
          <div className='row'>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>News</h4>
                <p className='service-research-p'>Summarized cryptocurrencies news
                  with only facts and figures.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Trending Market Value</h4>
                <p className='service-research-p'>Real-time prices of Bitcoin, Ethereum & coins
                  related to the news in the context. It represents
                  and visualizes in a graphical representation and
                  also in a summary format.
                </p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Education</h4>
                <p className='service-research-p'>To avoid any misleads, Education on
                  cryptocurrency is helpful.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Source Navigation</h4>
                <p className='service-research-p'>Sources from various publications like Cointelegraph, Bitcoin.com, CoinDesk, Fork log, Twitter, etc There are more than 100 websites in the source list, and it is updated consistently with new ones.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Categorical Filters</h4>
                <p className='service-research-p'>Looking for information on a particular crypto news? Categorize and Personalize according to our needs.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Market Updates</h4>
                <p className='service-research-p'>Provides updates about the overall coins inthe cryptocurrency, recently added coins, Coins that gained top and coins that lost most.</p>
              </div>
            </div>

          </div>

          <div className='service-research-img-content'>
            <div className='row'>
              <div className='col-12'>
                <div className='service-bg-img-div'>
                  <img src='./Images/Products/Cryptotrack_bg.jpg' className='service-bg-img' alt='img' />
                  <div className='service-bg-div'>
                    <h2 className='service-bg-h2'>A New Home for great Crypto Investors.</h2>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCt;