import React from 'react';
import './Product.css';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import WorkLottie from '../../../lotties/arrow-lottie.json';


function Services ()
{
    const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };
   let navigate = useNavigate();
  return ( 
    <>
      <div className="product-container container ">
        {/* section 1 */}
        <div className="row">
          <div className="product-section-1 col-8">
            {/* content-1 */}
            <div className="product-content-1 ">
              <h1 className='product-content-1-h1'><span className="product-span"></span>03</h1>
              <h2 className='product-content-1-h2'>Our Products</h2>
              <h6 className='product-content-1-h6'>Design and build high-impact digital
              products and customer experiences</h6>
            </div>
            </div>
            <div className='product-content-div-2 col-4'>
               <p  className='product-content-div-p' onClick={() => navigate('/Product')}>See all our products <div className='arrow-lottie-div'><Lottie options={lottieAnimation}/></div></p>
            </div>
        </div>

          {/* section-2 */}
        <div className='product-section-2 row'>
           <div className='col-lg-1'>
            &nbsp;
          </div>
            <div className='product-content-2 col-lg-3 col-md-4 col-sm-4 col-9'>
              <h1 className='product-content-2-h1'>01.</h1>
              <h5 className='product-content-2-h5'>Inventory</h5>
              <p className='product-content-2-p'>Automates your business by tracking two main functions of your warehouse / stockroom i.e. receiving(incoming) and shipping(outgoing)
              </p>
            </div>

            <div className='product-content-2 col-lg-3 col-md-4 col-sm-4 col-9'>
              <h1 className='product-content-2-h1'>02.</h1>
              <h5 className='product-content-2-h5'>C-fin</h5>
              <p className='product-content-2-p'>Accomplish the objectives of your business with efficient and effective of management of money(funds) for distribution & collection of payment.
              </p>
            </div>

            <div className='product-content-new-2 col-lg-3 col-md-4 col-sm-4 col-6'>
              <h1 className='product-content-2-h1'>03.</h1>
              <h5 className='product-content-2-h5'>CryptoTrack</h5>
              <p className='product-new-2-p product-content-2-p'>CryptoTrack News is a useful application for crypto enthusiasts. It includes an aggregator of news and articles, a crypto market monitoring feature, and a portfolio manager.
              </p>
            </div>
          </div>
        </div> 
    </>
   );
}

export default Services;