import React from "react";
import "./herosectionlearn.css";

export default function HerosectionGuide() {
  return (
    <>
    <div className="container-fluid blockChain_herosection">
      <div className="row ">
        <div className="col-lg-3 d-md-block d-none ">
          <div className="blockChainLearn_imgLeft">
            <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
          </div>
        </div>
        <div className="col-md-12 col-lg-6 text-center col-12 d-md-block d-flex justify-content-center">
          <div className="herotext">
            <img
              className="herologo mt-2"
              src="./Images/Blockchain_seo/logo.svg"
              alt="logo"
            />
            <h6 className="blockChain_herohead mt-4">
              <span className="">How to learn blockchain technology</span></h6>
            <p className="">An Expert Guidance</p>
          </div>
        </div>
        <div className="col-lg-3 d-md-block d-none">
          <div className=" blockChainLearn_imgRight ">
            <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
          </div>
        </div>
      </div>
    </div>
  </>
    // <div className="herosection">
    //   <div className="herotext">
    //     <img
    //       className="herologo"
    //       src="./Images/Blockchain_seo/logo.svg"
    //       alt="logo"
    //     />
    //     <h1 className="herohead">
    //       <strong>How to learn blockchain technology</strong>
    //       <br />
    //       An Expert Guidance
    //     </h1>
    //     <div className="herourl">
    //       <img src="./Images/Blockchain_seo/icon.svg" alt="globe-icon" />
    //       <a href="www.cloudinlabs.com">www.cloudinlabs.com</a>
    //     </div>
    //   </div>
    //   <div className="heroimg">
    //     <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
    //   </div>
    // </div>
  );
}
