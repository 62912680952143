import React from 'react';
import './about.css';
import Lottie from 'react-lottie';
import WorkLottie from '../../../lotties/home-about-lottie.json';
import { useNavigate } from 'react-router-dom';



function About ()
{

  let navigate = useNavigate();
  

  const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };
  return ( 
    <>
        <div className="about-container container ">
          {/* section 1 */}
          <div className="row">

          <div className="about-section-1 col-lg-7 col-md-6 col-sm-12">

            {/* content-1 */}
            <div className="about-content-1 ">

              <h1 className='about-content-1-h1'><span className="about-span"></span>01</h1>
              <h2 className='about-content-1-h2'>About Us</h2>
              <h6 className='about-content-1-h6'>WE ARE DIGITAL CREATIVE STUDIO IN COIMBATORE</h6>
            <p className='about-content-1-p'>Bring to the table win-win survival strategies to ensure
               proactive domination. At the end of the day, going forward, 
               a new normal that has evolved from generation on the 
              runway heading towards a streamlined cloud solution going forward.
            </p>
          </div>

          {/* content -2 */}
          <div className='about-content-2'>
            <div className='about-content-2-div'>
              <h1 className='about-content-2-h1'>08+</h1>
              <p className='about-content-2-p'>Experience</p>
            </div>

            <div className='about-content-2-div'>
              <h1 className='about-content-2-h1'>03+</h1>
              <p className='about-content-2-p'>Products</p>
            </div>

            <div className='about-content-2-div'>
              <h1 className='about-content-2-h1'>06+</h1>
              <p className='about-content-2-p'>Services</p>
            </div>

            <div className='about-content-2-div'>
              <h1 className='about-content-2-h1'>180+</h1>
              <p className='about-content-2-p'>Clients</p>
            </div>
          </div>

          <button className='about-content-2-btn' onClick={() => navigate('/About')}>Know More</button>
        </div>

        {/* section-2 image div */}
        <div className='about-section-2 col-lg-5 col-md-6 '>
          <div className='about-section-content'>
             {/* 
              
              <div className='about-position-img-2'>
                <img src='./Images/About/about image 2.jpg' alt='photo' className='about-img-2' />
              </div>

            */}
                <Lottie options={lottieAnimation} />
          
          </div>
        </div>
      </div>
    </div>
    </>
   );
}

export default About;