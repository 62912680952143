import React from 'react';
import './herosectionAdv.css';

export default function HerosectionAdv() {
    return(
             <>
      <div className="container-fluid blockChain_herosection">
        <div className="row ">
          <div className="col-lg-3 d-md-block d-none ">
            <div className="blockChain_imgLeft">
              <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 text-center col-12 d-md-block d-flex justify-content-center">
            <div className="herotext">
              <img
                className="herologo mt-2"
                src="./Images/Blockchain_seo/logo.svg"
                alt="logo"
              />
              <h6 className="blockChain_herohead mt-4">
                <span className="">Advantages of Using <br></br> Blockchain Technology</span></h6>
                 <p className="">Unlock the Potential of Decentralized Innovation!</p>
            </div>
          </div>
          <div className="col-lg-3 d-md-block d-none">
            <div className=" blockChain_imgRight ">
              <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
            </div>
          </div>
        </div>
      </div>
    </>
        // <div className='herosection'>
        //   <div className='herotext'>
        //     <img className='herologo' src='./Images/Blockchain_seo/logo.svg' alt='logo' />
        //     <h1 className='herohead'>What Are the Advantages of Using Blockchain Technology<br/>Unlock the Potential of Decentralized Innovation!</h1>
        //     <div className='herourl'>
        //       <img src='./Images/Blockchain_seo/icon.svg' alt='globe-icon'/>
        //       <a href="www.cloudinlabs.com">www.cloudinlabs.com</a>
        //     </div>
        //   </div>
        //   <div className='heroimg'>
        //     <img src='./Images/Blockchain_seo/blockchain.svg' alt='block'/>
        //   </div>
        // </div>
    )
}