import React, { useEffect, useState } from 'react';
import WorkLottie from '../lotties/Cloudin_Loader.json';
import Lottie from 'react-lottie';
import './loading.css';

function Loading ()
{

	const lottieAnimation = {
		loop: true,
		autoplay: true,
		animationData: WorkLottie,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
			id: 'loader-lottie-id',
			className: 'loader-lottie-id'
		}
	};

	const [ loading, setLoading ] = useState( true );

	useEffect(
		() =>
		{
			if ( loading )
			{
				setTimeout( () =>
				{
					setLoading( false );

				}, 2000 );
			}
		},
		[ loading ]
	);

	return (
		<>
			{
				loading &&
				<div className="main-loader overflow-hidden">
					<div className="loader">
						<Lottie options={lottieAnimation} className="loader-lottie" />
					</div>
				</div>
			}

		</>
	);
}

export default Loading;