import React from 'react';
import './services.css';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import WorkLottie from '../../../lotties/arrow-lottie.json';

function Services ()
{
    const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };
   let navigate = useNavigate();
  return ( 
    <>
      <div className="service-container container ">
        {/* section 1 */}
        <div className="row">
          <div className="service-section-1 col-8">
            {/* content-1 */}
            <div className="service-content-1 ">
              <h1 className='service-content-1-h1'><span className="service-span"></span>02</h1>
              <h2 className='service-content-1-h2'>Our Services</h2>
              <h6 className='service-content-1-h6'>Making the world a better place through impactful software</h6>
            </div>
            </div>
            <div className='service-content-div-2 col-4'>
            <p className='product-content-div-p' onClick={() => navigate( '/Services' )}>See all our Services <div className='arrow-lottie-div'><Lottie options={lottieAnimation} /></div></p>
            </div>
        </div>

          {/* section-2 */}
        <div className='service-section-2 mt-3 row'>
          
          <div className='col-lg-1'>
            &nbsp;
          </div>
            <div className='service-content-2 col-lg-3 col-md-4 col-sm-4 col-11'>
              <h1 className='service-content-2-h1'>01.</h1>
              <h5 className='service-content-2-h5'>UX & UI Design</h5>
              <p className='service-content-2-p'>User experiences which are based on in-depth research and sound analysis result in better customer relationships and more revenue. We’ll help you gain real insight into your customers’ needs and build experiences to meet them.
              </p>
            </div>

            <div className='service-content-2 col-lg-3 col-md-4 col-sm-4 col-11'>
              <h1 className='service-content-2-h1'>02.</h1>
              <h5 className='service-content-2-h5'>UX Consulting</h5>
              <p className='service-content-2-p'>Our UX consultants will work closely with your team throughout a project, acting as the bridge between technology and design. We’ll help you deliver more value to your users and achieve your business objectives.
              </p>
            </div>

            <div className='service-content-new-2 col-lg-3 col-md-4 col-sm-4 col-11'>
              <h1 className='service-content-2-h1'>03.</h1>
              <h5 className='service-content-2-h5'>Development</h5>
              <p className='service-content-new-2-p service-content-2-p'>Great user experiences add value to your business by helping to attract the right attention and driving customer acquisition. We can help you craft customer experiences which accelerate business growth.
              </p>
            </div>
          </div>
        </div> 
    </>
   );
}

export default Services;