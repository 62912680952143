import React from 'react';

function MobileDeptTech() {
  return ( 
    <>
      <section className='web-tech-section container my-4'>
        <div className='web-tech-content-div'>
          <div className='row'>
            <div className='col-12'>
              <div className='web-tech-div-1 d-md-flex'>
                <div className='col-md-5 col-12'>
                  <h1 className='web-tech-h2 h2 lh-base fw-bold'>Our Technology<br/> Expertise</h1>  
                </div>
                <div className='col-md-7 col-12'>
                  <p className='web-tech-p'>Developing powerful websites is only possible with the right technology. Our professional corporate website development engineers will combine multiple technologies to achieve the anticipated result and deliver a modern, fast, and easily maintainable web solution.</p>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/mobile-dev/android.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Android</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/mobile-dev/ios.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>ios</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/react.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>React</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/mobile-dev/xamarin.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Xamarin</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/mobile-dev/kotlin.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Kotlin</h6>
              </div>
            </div>
            {/*<div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/mobile-dev/ionic.svg' className='web-tech-img' /> 
                </div>
                  <h6 className='web-tech-h6'>Ionic</h6>
              </div>
  </div>*/}

            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/mobile-dev/flutter.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Flutter</h6>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
   );
}

export default MobileDeptTech;