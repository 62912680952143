import React from 'react';

function ProductCfin() {
  return ( 
    <>
            <div className='service-research-div container'>
        <div className='service-research-content'>
          <h4 className='service-research-head fw-bold mb-3 h5'>CFIN : CHANGING EXPENSES LIFE INTO PLANS</h4>
          <h6 className='service-research-h6'>CFin is a centralized payment and collection hub with real-time visibility and control.
          </h6>
          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Party Allocation</h4>
                <p className='service-research-p'>Allocates the collector, the borrower, 
its detailed information to collect debt.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Debt Collection</h4>
                <p className='service-research-p'>Collector collects the debt / borrowed 
money from the debtor / borrower and 
send the reports</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Ledger</h4>
                <p className='service-research-p'>Develops the ledger book compact 
design as it is a key process in financial 
management.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Day Book</h4>
                <p className='service-research-p'>Reports the incoming (Collection) and 
outgoing (Distribution) of money flows 
with ranges of reports</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Loan Approval</h4>
                <p className='service-research-p'>Ability to approve loan based on detailed 
information, loan repayment, mode of 
payment, etc.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Loan Summary</h4>
                <p className='service-research-p'>Summarize the loan pending amount, 
dues, amount collect, and other details 
of the borrower.</p>
              </div>
            </div>

          </div>

          <div className='service-research-img-content'>
            <div className='row'>
              <div className='col-12'>
                <div className='service-bg-img-div'>
                  <img src='./Images/Products/cfin-bg.jpg' className='service-bg-img' alt='img' />
                  <div className='service-bg-div'>
                    <h2 className='service-bg-h2 text-center'>A Simple & Effective Way of Accounting</h2>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
   );
}

export default ProductCfin;