import React from 'react';
import './ServiceTesting.css'

function ServiceTesting() {
  return ( 
    <>
      <div className='service-research-div container'>
        <div className='service-research-content'>
          {/* <h6 className='service-research-h6'>No software development project (and we really mean this!) can be rolled out without extensive 
software testing and QA. Our software development services have a holistic approach to testing — 
automating as much as we can (to save your time and budget), and testing the rest on over 250 
  physical devices to ensure optimal performance regardless of the devices your clients/users will be working on.</h6>*/}
          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Manual Testing</h4>
                <p className='service-research-p'>Manual testing is an important part of software quality assurance, where a human tester proceeds a quality check without using any automation tools. It aims to verify or disprove that the software works as expected and meets the technical and business criteria.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Automated Testing</h4>
                <p className='service-research-p'>Automation testing is the application of tools and technology to testing software with the goal of reducing testing efforts, delivering capability faster and more affordably. It helps in building better quality software with less effort.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Security Testing</h4>
                <p className='service-research-p'>Security is a big deal in modern application development. Business logic is getting more and more complicated. Security testing is not just about testing the application by breaking into it, but security testing is also about identifying weaknesses in applications that attackers may exploit.</p>
              </div>
            </div>

          </div>

          <div className='service-research-img-content'>
            <div className='row'>
              <div className='col-12'>
                <div className='service-bg-img-div'>
                  <img src='./Images/Services/testing-bg.jpg' className='service-bg-img' alt='img' />
                  <div className="service-bg-div text-left">
                    <h2 className='service-bg-h2'>Software never was perfect and won't get perfect. But is that a license to create
                    garbage? The missing ingredient is our reluctance to quantify quality.</h2>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
   );
}

export default ServiceTesting;