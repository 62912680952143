import React from 'react';
import '../../HomeContainers/Testimonials/Testimonial.css';
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";


import SwiperCore, { Autoplay, EffectCreative } from 'swiper';
SwiperCore.use( [ Autoplay, EffectCreative ] );

function WorkTesti ()
{
  return (
    <>
      <div className="testi-container container-fluid">
        <div className="testi-container-contents row">
          {/* section 1 */}

          <div className="testi-content-imgs col-lg-3 ">
            <div className='testi-div-1 floating'>
              <img src="./Images/Testi/testi-1.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-2 floating'>
              <img src="./Images/Testi/testi-2.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-3 floating'>
              <img src="./Images/Testi/testi-3.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-4 floating'>
              <img src="./Images/Testi/testi-4.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-5 floating'>
              <img src="./Images/Testi/testi-5.jpg"  alt='img'  className="testi-img" />
            </div>
          </div>

          {/* section 2 */}
          <div className="testi-section-1 col-lg-6 col-md-12">
            {/* content-1 */}
            <div className="testi-content-1 ">
              <h1 className='testi-content-1-h1'><span className="service-span"></span>04</h1>
              <br />
              <h2 className='testi-content-1-h2'>Testimonials</h2>
              <h6 className='testi-content-1-h6'>OUR HAPPY CLIENTS SAYS</h6>
            </div>

            <div className="testi-quote-content">
              {/* quote-1 */}
              <Swiper
                className="quote-anime"
                effect="Creative"
                slidesPerView={1}
                direction={"vertical"}
                autoplay={{ delay: 4000 }}
              >
                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'Thank you cloudin, for beautiful, responsive, low budget but well designed website.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span >CEO</div>
                      <div className="quote-job">Aqua care</div>
                    </div>
                  </div>
                </SwiperSlide>


                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'Few things in life are truly definitive. Cloudin is well on its way to becoming one of them.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span>Lokeshwaran</div>
                      <div className="quote-job">CEO at Apple Green</div>
                    </div>
                  </div>
                </SwiperSlide>

                {/* quote-3 */}
                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'This software very useful to us, it simplifies our invoice process, we handle inbound and outbound without confusion. Thank you cloudin.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span>Siva</div>
                      <div className="quote-job">CEO at WRAPUP</div>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>
          </div>

          {/* section 3 */}

          <div className="testi-content-imgs col-lg-3">
            <div className='testi-div-6 floating'>
              <img src="./Images/Testi/testi-6.jpg"  alt='img'  className="testi-img" />
            </div>
            <div className='testi-div-7 floating'>
              <img src="./Images/Testi/testi-7.jpg"  alt='img'  className="testi-img" />
            </div>
            <div className='testi-div-8 floating'>
              <img src="./Images/Testi/testi-8.jpg"  alt='img'  className="testi-img" />
            </div>
            <div className='testi-div-9 floating'>
              <img src="./Images/Testi/testi-9.jpg"  alt='img'  className="testi-img" />
            </div>
            <div className='testi-div-10 floating'>
              <img src="./Images/Testi/testi-10.jpg"  alt='img'  className="testi-img" />
            </div>
            <div className='testi-div-11 floating'>
              <img src="./Images/Testi/testi-11.jpg"  alt='img'  className="testi-img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkTesti;