import React from "react";
import HerosectionGuide from "../Components/BlockchainGuide/HerosectionGuide/herosectionguide";
import ContentGuide from "../Components/BlockchainGuide/ContentGuide/contentguide";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import NewNavbar from "../NewNavbar/NewNavbar";
import "../App.css";
import SEO from '../seo';
import { Helmet } from "react-helmet";


function BlockchainLearn() {

  const canonicalUrl = window.location.href;

  return (
    <>
    <Helmet>
        <title>What Is Blockchain Development - A Guide to Getting Started - Cloudinlabs</title>
        <meta name="description"  content="Discover the power of blockchain development and its transformative potential. Uncover the answer to the question, What Is Blockchain Development? and unlock a decentralized future."/>
    </Helmet>
               
      <Loading />
      <NewNavbar />
      <div className="Blockchainpage">
        <header className="App-header">
          <HerosectionGuide />
          <ContentGuide />
        </header>
      </div>
      <Footer />
    </>
  );
}

export default BlockchainLearn;
