import React from "react";
import "./ServiceBuild.css";

import { useNavigate } from "react-router-dom";

function ServiceBuild() {
  const navigate = useNavigate();

  return (
    <>
      <div className="service-research-div container">
        <div className="service-research-content">
          {/* <h6 className='service-research-h6'>We provide meaningful and actionable data driven insights that represent the voices of a user and resonate with business objectives.</h6>*/}
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">01.</h2>
                </div>
                <h4 className="service-research-h4">Web Applications</h4>
                <p className="service-research-p">
                  We are the web application development agency chosen by global
                  brands to improve efficiency, develop new products, improve
                  reporting and unlock revenue.
                </p>
                <button
                  className="service-build-btn"
                  onClick={() => navigate("/web-development-coimbatore")}
                >
                  Know more
                </button>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">02.</h2>
                </div>
                <h4 className="service-research-h4">Mobile Applications</h4>
                <p className="service-research-p">
                  Crafting top-notch mobile experiences that offers high
                  scalability and get ahead of competition.
                </p>
                <button
                  className="service-build-btn"
                  onClick={() => navigate("/mobileapps-development-coimbatore")}
                >
                  Know more
                </button>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">03.</h2>
                </div>
                <h4 className="service-research-h4">Artificial Intelligence</h4>
                <p className="service-research-p">
                  Empower change, drive innovation and create exciting new
                  possibilities through smart & Agile AI.
                </p>
                <button
                  className="service-build-btn"
                  onClick={() => navigate("/artificial-intelligence-coimbatore")}
                >
                  Know more
                </button>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">04.</h2>
                </div>
                <h4 className="service-research-h4">ML & NLP</h4>
                <p className="service-research-p">
                  Combining machine learning & natural language processing boost
                  accuracy and understand of human utterances.
                </p>
                <button
                  className="service-build-btn"
                  onClick={() => navigate("/machine-learning-coimbatore")}
                >
                  Know more
                </button>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">05.</h2>
                </div>
                <h4 className="service-research-h4">Digital Marketing</h4>
                <p className="service-research-p">
                  No matter what you're looking for, we can create a custom
                  solution for your business.
                </p>
                <button
                  className="service-build-btn"
                  onClick={() => navigate("/digital-marketing-service-coimbatore")}
                >
                  Know more
                </button>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">06.</h2>
                </div>
                <h4 className="service-research-h4">IT Consulting</h4>
                <p className="service-research-p">
                  Helping clients with smarter technology decisions, increases
                  ROI and continuously improve efficiency
                </p>
                <button
                  className="service-build-btn"
                  onClick={() => navigate("/information-technology-consulting-coimbatore")}
                >
                  Know more
                </button>
              </div>
            </div>
          </div>

          <div className="service-research-img-content">
            <div className="row">
              <div className="col-12">
                <div className="service-bg-img-div">
                  <img
                    src="./Images/Services/build-bg.jpg"
                    className="service-bg-img"
                    alt="img"
                  />
                  <div className="service-bg-div">
                    <h2 className="service-bg-h2">
                      One of the most important tasks for a founder is to make
                      sure the company builds a great product.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceBuild;
