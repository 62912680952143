import React from "react";
import MlDeptAbout from "../Components/DeptContainer/MlDept/MlDeptAbout/MlDeptAbout";
import MlDeptDiscuss from "../Components/DeptContainer/MlDept/MlDeptDiscuss/MlDeptDiscuss";
import MlDeptHome from "../Components/DeptContainer/MlDept/MlDeptHome/MlDeptHome";
import MlDeptService from "../Components/DeptContainer/MlDept/MlDeptService/MlDeptService";
import MlDeptTech from "../Components/DeptContainer/MlDept/MlDeptTech/MlDeptTech";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";


function MlDeptApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
    <SEO
        title="Solutions for Machine Learning from Cloudin Labs"
        description="Let Cloudin Labs Explore innovative strategies that boost productivity and insights for your business. adopt ML right away! "
        url={canonicalUrl}
        status="index"
      />
      <Loading/>
      <Navbar />
      <MlDeptHome />
      <MlDeptAbout />
      <MlDeptService />
      <MlDeptTech />
      <MlDeptDiscuss />
      <Footer />
    </>
  );
}

export default MlDeptApp;
