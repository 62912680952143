import React from "react";
import AboutApp from "./AppContainers/AboutApp";
import ContactApp from "./AppContainers/ContactApp";
import HomeApp from "./AppContainers/HomeApp";
import WorkApp from "./AppContainers/WorkApp";
import CareerApp from "./AppContainers/CareerApp";
import { Routes, Route, Navigate} from "react-router-dom";
import ServiceApp from "./AppContainers/ServiceApp";
import WebDeptApp from "./AppContainers/WebDeptApp";
import MobileDeptApp from "./AppContainers/MobileDeptApp";
import AiDeptApp from "./AppContainers/AiDeptApp";
import MlDeptApp from "./AppContainers/MlDeptApp";
import DmDeptApp from "./AppContainers/DmDeptApp";
import ItDeptApp from "./AppContainers/ItDeptApp";
import ProductApp from "./AppContainers/ProductApp";
import ScrollToTop from "./ScrollToTop";
import TcApp from "./AppContainers/TcApp";
import PpApp from "./AppContainers/PpApp";
import BlockchainApp from "./AppContainers/BlockchainApp";
import BlockchainLearn from "./AppContainers/BlockchainLearn";
import BlockchainAdv from "./AppContainers/BlockchainAdv"; 
import BlockchainGuide from "./AppContainers/BlockchainGuide";
// import Helmet from "react-helmet";

function App() {
  return (
    <div className="App">
        

      <ScrollToTop>
        <Routes>
          
          <Route path="/" element={<HomeApp />} />
          <Route path="about" element={<AboutApp />} />
          <Route path="work" element={<WorkApp />} />
          <Route path="contact" element={<ContactApp />} />
          <Route path="career" element={<CareerApp />} />
          <Route path="services" element={<ServiceApp />} />
          <Route path="web-development-coimbatore" element={<WebDeptApp />} />
          <Route path="mobileapps-development-coimbatore" element={<MobileDeptApp />} />
          <Route path="artificial-intelligence-coimbatore" element={<AiDeptApp />} />
          <Route path="machine-learning-coimbatore" element={<MlDeptApp />} />
          <Route path="digital-marketing-service-coimbatore" element={<DmDeptApp />} />
          <Route path="information-technology-consulting-coimbatore" element={<ItDeptApp />} />
          <Route path="product" element={<ProductApp />} />
          <Route path="terms_and_condition" element={<TcApp />} />
          <Route path="privacy_policy" element={<PpApp />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route
            path="blockchain-software-development-company-coimbatore"
            element={<BlockchainApp />}
          />
          <Route
            path="how-learn-blockchain-technology"
            element={<BlockchainLearn/>}
          />
          <Route
            path="advantages-blockchain-technology"
            element={<BlockchainAdv/>}
          />
          <Route
            path="blockchain-development-guide"
            element={<BlockchainGuide/>}
          />
        </Routes>
      </ScrollToTop>
      {/* <Helmet>
        <link rel="canonical" href="https://cloudinlabs.com/" />
      </Helmet> */}
    </div>
  );
}

export default App;
