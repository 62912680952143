import React from 'react';
import './ServiceScroll.css';
import ScrollSpy from "react-ui-scrollspy";
import { Link } from "react-scroll";

import ServiceResearch from '../ServiceResearch/ServiceResearch';
import ServiceDesign from '../ServiceDesign/ServiceDesign';
import ServiceStrategy from '../ServiceStrategy/ServiceStrategy';
import ServiceBuild from '../ServiceBuild/ServiceBuild';
import ServiceTesting from '../ServiceTesting/ServiceTesting';


  

function ServiceScroll ()
{



  return ( 
    <>
      <section className='service-scroll-section container'>

        <ul className='service-nav-ul'>
          <Link to="Research" smooth={true} offset={-160} duration={0} className='service-nav-li' data-to-scrollspy-id="Research">
            <h6>Research</h6>
            <div className='service-nav-line'></div>
          </Link>
          <Link to="Design" smooth={true} offset={-160} duration={0} className='service-nav-li' data-to-scrollspy-id="Design">
            <h6>Design</h6>
            <div className='service-nav-line'></div>
          </Link>
          <Link to="Strategy" smooth={true} offset={-160} duration={0} className='service-nav-li' data-to-scrollspy-id="Strategy">
            <h6>Strategy</h6>
            <div className='service-nav-line'></div>
          </Link>
          <Link to="Build" smooth={true} offset={-160} duration={0} className='service-nav-li' data-to-scrollspy-id="Build">
            <h6>Build</h6>
            <div className='service-nav-line'></div>
          </Link>
          <Link to="Testing" smooth={true} offset={-160} duration={0} className='service-nav-li' data-to-scrollspy-id="Testing">
            <h6>Testing</h6>
            <div className='service-nav-line'></div>
          </Link>         
        </ul>
        

        <ScrollSpy className='service-scroll-div'>
          <section id="Research"><ServiceResearch /></section>
          
          <section id="Design"><ServiceDesign /></section>

          <section id="Strategy"><ServiceStrategy /></section>
          
          <section id="Build"><ServiceBuild /></section>

          <section id="Testing"><ServiceTesting/></section>
        </ScrollSpy>

        
      </section>
    </>
   );
}

export default ServiceScroll;










