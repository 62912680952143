import React from 'react';
import './ServiceDesign.css';



function ServiceDesign() {
  return ( 
    <>
      <div className='service-research-div container'>
        <div className='service-research-content'>
          {/*<h6 className='service-research-h6'>We provide meaningful and actionable data driven insights that represent the voices of a user and resonate with business objectives.</h6>*/}
          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>User Experience Design</h4>
                <p className='service-research-p'>We identify and evaluate all aspects, perceptions and points of interaction between a user and a computer system. These generally include the visual design, information architecture, usability and accessibility of the primary system.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>User Interface Design</h4>
                <p className='service-research-p'>From the typography to the colour, every aspect of a digital screen influences the user’s interaction, and satisfaction, with the app or website. UI design involves the complex art of arranging the elements.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Interaction Design</h4>
                <p className='service-research-p'>We look at how images, typography, and icons on a website, app, or service supplement the words on the page to communicate information to a user. Or they might think about the hardware a user uses.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Information Architecture</h4>
                <p className='service-research-p'>We will classify the content on a website or web application in a way that is understandable, clear, and intuitive. Then, it needs to be organized in a user-centric way, so that users find what they need effortlessly.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Prototyping</h4>
                <p className='service-research-p'>We bring your ideas to life in animated prototypes. Test concepts earlier and more often. Create a better blueprint for development.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Illustrations & Iconography</h4>
                <p className='service-research-p'>We create form of communication that adds to a brand’s visual language, so a custom icon set is more meaningful and engaging than a simple, generic one.</p>
              </div>
            </div>

          </div>

          <div className='service-research-img-content'>
            <div className='row'>
              <div className='col-12'>
                <div className='service-bg-img-div'>
                  <img src='./Images/Services/design-bg.jpg' className='service-bg-img' alt='img' />
                  <div className='service-bg-div'>
                    <h2 className='service-bg-h2'>Design is the intermediary between information and understanding.</h2>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
   );
}

export default ServiceDesign;