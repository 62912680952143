import React from "react";
import Herosection from "../Components/BlockchainContainer/BlockchainSeo/Herosection/herosection";
import Content from "../Components/BlockchainContainer/BlockchainSeo/Content/content";
import "../App.css";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import NewNavbar from "../NewNavbar/NewNavbar";
import SEO from '../seo';

function BlockchainApp() {

  const canonicalUrl = window.location.href;

  return (
    <>
    <SEO
        title="#1 Best Blockchain Software Development Company In Coimbatore"
        description=" Looking for a reliable blockchain software development company in Coimbatore? Get top-notch blockchain solutions tailored to your needs. Contact us today!"
        url={canonicalUrl}
        status = "index"
      />
      <Loading />
      <NewNavbar />
      <div className="Blockchainpage">
        <header className="App-header">
          <Herosection />
          <Content />
        </header>
      </div>
      <Footer />
    </>
  );
}

export default BlockchainApp;
