import React, {useRef, useState} from 'react';
import './ContactForm.css'; 
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

function ContactForm ()
{

  const form = useRef();
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    reset
  } = useForm();
  



  const [ data, setData ] = useState( {} )
  
  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };


  
  const sendEmail = ( data,e ) =>
  {
    e.preventDefault();
    
    emailjs.sendForm(
      "service_l029rhu",
      "template_4q0p40m",
      form.current,
      "Sz5nHTgdr-rAs6Oht",)
      .then((result) => {
        Swal.fire(
          "Good job!",
          "Message Send Successfully :)",
          "success",
          "Close",
      )
      }, (error) => {
         Swal.fire({
      title: "Error!",
      text: "Something went wrong message could not be sent :) ",
      icon: "error",
      button: "ok!",
       })
      } );

     

  console.log(data);
   reset();
  } 
   
  
  // const onError = (errors) => {
  //   Swal.fire({
  //     title: "Error!",
  //     text: "Enter all the Inputs",
  //     icon: "error",
  //     button: "ok!",
  // })
  // }



  return ( 
    <>
      <div className="contact-form-container container">
        <div className="contact-form-sec row">
          {/* sec -1 */}
          <div className="contact-form-1 col-lg-3 col-md-3 col-sm-12 col-12">
            <div className="contact-form-head">
              <h6 className="contact-form-title">Contact</h6>
              <span className="contact-line"></span>
            </div>

            <div className="contact-form-body-1">
              <h6 className="contact-h6">Business enquiry</h6>
              <h4 className="contact-h4">business@cloudinlabs.com</h4>
            </div>

            <div className="contact-form-body-2">
              <h6 className="contact-h6">Jobs & Internship</h6>
              <h4 className="contact-h4">careers@cloudinlabs.com</h4>

              <h6 className="contact-h6">Sales</h6>
              <h4 className="contact-h4">sales@cloudinlabs.com</h4>
    
            </div>
          </div>  


          {/* sec -2 */}

          <div className="contact-form-2 ms-sm-5 col-lg-8 col-md-8 col-sm-11 col-12">
            <div className="contact-form-row-2 row">
              <div className="contact-form-head contact-head-new col-8 mx-sm-5">
              <h6 className="contact-form-title">Contact Us</h6>
              <span className="contact-line"></span>
              </div>
            </div>  

            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
              <div className="row">
                <div className="mb-3 col-lg-5 col-md-5 col-sm-4 col-12 contact-input-div">
                  <input
                    name='fullName'
                    type="text"
                    maxLength={50}
                    placeholder="Your Name"
                    className="contact-input name"
                    onChange={e => onInputChange(e)}
                    {...register( "fullName", {
                      required: "Name is required",
                      maxLength: 20,
                      minLength: 1,
                      pattern: {
                      value: /^[A-Z a-z]+$/,
                      message: "Value is Invalid",
                      }
                    } )}
                    onKeyUp={() => {
                    trigger("fullName");
                  }}
                  />
              {errors.fullName &&
              (<small className='text-danger error-text'>{errors.fullName.message} </small>)}
                </div>

              <div className="mb-3 col-lg-5 col-md-5 col-sm-4  col-12  contact-input-div-1">
                  <input
                    type="text"
                    name='email'
                    maxLength={35}
                    placeholder="E-Mail ID"
                    className="contact-input name"
                    onChange={e => onInputChange(e)}
                    {...register( "email", {
                      required: "Email is required",
                      pattern: {
                      value: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                      message: "Value is Invalid",
                      }
                    } )}
                    onKeyUp={() => {
                    trigger("email");
                    }} />
                  {errors.email &&
                  (<small className='text-danger error-text'>{errors.email.message} </small>)}
              </div>
              </div>
             
              <div className='row'>
                <div className=" mb-3 col-md-5 col-sm-4  col-12 contact-input-div">
                  <input
                    type="text"
                    maxLength={10}
                    name='phone'
                    placeholder='Phone Number'
                    className="contact-input name"
                    onChange={e => onInputChange(e)}
                    {...register( "phone", {
                      required: "phone number is required",
                      maxLength: 10,
                      pattern: {
                      value: /^[6-9]{1}[0-9]{9}$/,
                      message: "Value is Invalid",
                      }
                    } )}
                    onKeyUp={(e) => {
                    trigger("phone")
                    }
                  } 
                  onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  />
                  {errors.phone &&
                  (<small className='text-danger error-text'>{errors.phone.message} </small>)}
              </div>
              
              <div className="mb-3 col-md-5 col-sm-4  col-12  contact-input-div-1">
                  <input
                    type="text"
                    name='industry'
                    placeholder='Industry Type'
                    className="contact-input name"
                    onChange={e => onInputChange(e)}
                    {...register( "industry", {
                      required: "Industry is required",
                      pattern: {
                      value: /[A-Za-z0-9]/,
                      message: "Value is Invalid",
                      }
                    } )}
                    onKeyUp={() => {
                    trigger("industry");
                    }} 
                  />
                  {errors.industry &&
                  (<small className='text-danger error-text'>{errors.industry.message} </small>)}
              </div>
              </div>


              <div className='row'>
                <div className="mb-3 col-md-5 col-sm-4  col-12 contact-input-div">
                  <select
                  className="form-select"
                  name='region'
                  {...register( "region", {
                      required: "Region is required",
                     } )} 
                  >
                  <option value="">Select Region</option>
                  <option value="Coimbatore">Coimbatore</option>
                  </select>
                  {errors.region &&
                  (<small className='text-danger  error-text'>{errors.region.message} </small>)}
              </div>

              <div className="mb-3 col-md-5 col-sm-4  col-12 contact-input-div-1">
                  <select
                    className="form-select"
                   name='service'
                  {...register( "service", {
                      required: "Service is required",
                     } )} >
                  <option value="" className='main-option'>Services</option>
                  <option value="Web Application"  className='sub-option'>Web Application</option>
                  <option value="Mobile Application"  className='sub-option'>Mobile Application</option>
                  <option value="Artificial Intelligence"  className='sub-option'>Artificial Intelligence</option>
                  <option value="ML & NLP">ML & NLP</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="IT Consulting">IT Consulting</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.service &&
                  (<small className='text-danger error-text'>{errors.service.message}</small>)}
              </div>
              </div>
              

              <div className='row '>
                <div className="mb-3 col-lg-11 col-md-10  col-12 contact-text-div">
                  <textarea
                    className="contact-input textarea-input"
                    rows={7}
                    name='message'
                    placeholder="Your Message Here"
                  {...register( "message", {
                      required: "Message is required",
                  } )}
                  ></textarea>
                  {errors.message &&
                  (<small className='text-danger textarea-input error-text'>{errors.message.message}</small>)}
                </div>
              </div>
              

              <button type='submit' value="Send" className='btn btn-info mx-sm-5 px-5 py-2 '>Submit</button>
            </form>
            </div>
          {/* </div> */}


        </div>
      </div>
    </>
   );
}

export default ContactForm;