/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import "./navbar.css";
import { CgMenuRight } from "react-icons/cg";
import { MdCancel } from "react-icons/md";
import "../Components/HomeContainers/About/about.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Navbar ()
{
  const [ showMediaIcon, setShowMediaIcon ] = useState( false );

  const handleClick = () =>
  {
    this.setState( { clicked: !this.state.clicked } );
  };

  const [ color, setColor ] = useState( false );

  const changeColor = () =>
  {
    if ( window.scrollY >= 100 )
    {
      setColor( true );
    } else
    {
      setColor( false );
    }
  };

  window.addEventListener( "scroll", changeColor );

  let navigate = useNavigate();

  return (
    <>
      <nav>
        <div
          className={
            color
              ? "navbar-container container-fluid  navbar-bg"
              : "navbar-container container-fluid"
          }
        >
          <div className="navbar-logo" id="logo" onClick={() => navigate( "/" )}>
            {window.scrollY >= 100 ? (
              <img
                src="./Images/cloudin-logo-black.svg"
                className="navbar-logo-img"
                id="navbar-logo-img"
                alt='img'
                width="30%"
              />
            ) : (
              <img
                src="./Images/cloudin-logo-1.svg"
                className="navbar-logo-img-1"
                id="navbar-logo-img-1"
                alt='img'
                width="30%"
              />
            )}
          </div>

          <div className={showMediaIcon ? " show-menu" : "hide-menu"}>
            <ul className="nav-ul">
              {/* <li>
              <a href="#" >Home</a>
            </li> */}
              {/*<li>
              <NavLink to='/' onClick={() => setShowMediaIcon(!showMediaIcon)}>Home</NavLink>
          </li>*/}
              <li>
                <NavLink
                  to="/about"
                  onClick={() => setShowMediaIcon( !showMediaIcon )}
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  onClick={() => setShowMediaIcon( !showMediaIcon )}
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/product"
                  onClick={() => setShowMediaIcon( !showMediaIcon )}
                >
                  Product
                </NavLink>
              </li>
              {/* <li>
              <NavLink to='/Work' onClick={() => setShowMediaIcon(!showMediaIcon)}>Works</NavLink>
            </li> */}
              <li>
                <NavLink
                  to="/career"
                  onClick={() => setShowMediaIcon( !showMediaIcon )}
                >
                  Careers
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  onClick={() => setShowMediaIcon( !showMediaIcon )}
                >
                  Let's Talk
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="hamburger-menu">
            <div onClick={() => setShowMediaIcon( !showMediaIcon )}>
              <i onClick={handleClick}>
                {showMediaIcon ? (
                  <MdCancel className="cancel-icon" />
                ) : (
                  <CgMenuRight
                    className={color ? "menu-icon-bg" : "menu-icon"}
                  />
                )}
              </i>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
