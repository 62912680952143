import React from 'react';

function AiDeptService() {
  return ( 
    <>
       <section className='web-service-section container'>
        <div className='web-service-content-div px-md-0 px-sm-4 px-2'>
          <h1 className='web-service-content-1-h1'><span className="web-service-span"></span>OUR WORKING PROCESS</h1>
          <h2 className='web-service-content-1-h2'>Our advanced and easily-integrable capabilities addresses various business needs</h2>

          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Data Management</h4>
                <p className='service-research-p'>Our Data provides highly effective tools and frameworks for complex data work-flows to power further ML experimentation.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Machine Learning</h4>
                <p className='service-research-p'>Our AI application development is focused on Machine Learning enabling machines to be applied for self-learning and decision making.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Model Operations</h4>
                <p className='service-research-p'>Our solutions interpret important data, identify trends, and identify similar patterns that help businesses make better decisions.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Virtual Assistants</h4>
                <p className='service-research-p'>Our AI application development service aims to deliver the rich customer experience and extensive support through chatbot and voice-enabled interfaces</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Natural Language Processing</h4>
                <p className='service-research-p'>We focus on enabling machines to understand how people speak and write in their natural styles.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Visual Technology</h4>
                <p className='service-research-p'>Cloudin Labs also develops ultra-modern visual apps that obtain, analyze, and synthesize images and recognize patterns in them.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
   );
}

export default AiDeptService;