import React from 'react';
import AiDeptAbout from '../Components/DeptContainer/AiDept/AiDeptAbout/AiDeptAbout';
import AiDeptDiscuss from '../Components/DeptContainer/AiDept/AiDeptDiscuss/AiDeptDiscuss';
import AiDeptHome from '../Components/DeptContainer/AiDept/AiDeptHome/AiDeptHome';
import AiDeptService from '../Components/DeptContainer/AiDept/AiDeptService/AiDeptService';
import AiDeptTech from '../Components/DeptContainer/AiDept/AiDeptTech/AiDeptTech';
import Footer from '../Footer/Footer';
import Loading from '../Loading/loading';
import Navbar from '../Navbar/Navbar';
import SEO from '../seo';


function AiDeptApp() {
  const canonicalUrl = window.location.href;

  return ( 
    <>
     <SEO
        title="artificial-intelligence-coimbatore"
        description="Discover the breakthrough artificial intelligence (AI) solutions from Cloudin Labs. Explore artificial intelligence's potential for increased productivity and creativity."
        url={canonicalUrl}
        status="index"
      />
      <Loading/>
      <Navbar/>
      <AiDeptHome />
      <AiDeptAbout />
      <AiDeptService />
      <AiDeptTech />
      <AiDeptDiscuss />
      <Footer/>
    </>
   );
}

export default AiDeptApp;