/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './Testimonial.css';
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";


import SwiperCore, { Autoplay, EffectCreative } from 'swiper';
SwiperCore.use( [ Autoplay, EffectCreative ] );

function Testimonials ()
{
  return (
    <>
      <div className="testi-container container-fluid">
        <div className="testi-container-contents row">
          {/* section 1 */}

          <div className="testi-content-imgs col-lg-3 ">
            <div className='testi-div-1 floating'>
              <img src="./Images/Testi/testi-1.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-2 floating'>
              <img src="./Images/Testi/testi-2.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-3 floating'>
              <img src="./Images/Testi/testi-3.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-4 floating'>
              <img src="./Images/Testi/testi-4.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-5 floating'>
              <img src="./Images/Testi/testi-5.jpg" alt='img' className="testi-img" />
            </div>
          </div>

          {/* section 2 */}
          <div className="testi-section-1 col-lg-6 col-md-12">
            {/* content-1 */}
            <div className="testi-content-1 ">
              <h1 className='testi-content-1-h1'><span className="service-span"></span>04</h1>
              <h2 className='testi-content-1-h2'>Testimonials</h2>
              <h6 className='testi-content-1-h6'>OUR HAPPY CLIENTS SAYS</h6>
            </div>

            <div className="testi-quote-content">
              {/* quote-1 */}
              <Swiper
                className="quote-anime"
                effect="Creative"
                slidesPerView={1}
                direction={"vertical"}
                autoplay={{ delay: 4000 }}
              >
                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'Cloudin labs is a stellar technology company. Design and Development of our new website were completed in a few short days and have improved customer acquisition.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span >Gazo</div>
                      <div className="quote-job">CEO at Gazo</div>
                    </div>
                  </div>
                </SwiperSlide>


                {/* <SwiperSlide>
                     <div className="quote">
                    <p className="quote-p">'When I first was trying to make my website, I tried to do it myself online. After several attempts to make something to my liking. I finally started to solicit some professional help. Sakthi from Cloudin took the time to meet me and was very professional but also the most personable one I had met with'</p>
                    <div class="quote-info">
                      <div class="quote-name"><span className="pp"></span>Lokeshwaran</div>
                      <div class="quote-job">CEO at Apple Green</div>
                     </div>
                  </div>
  </SwiperSlide>*/}

                {/* quote-3 */}
                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'Cloudin team has been fantastic to deal with.That I have found with the most personal and best service to match! Very happy with everything they have done for me and will be going back time and time again for their services.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span>360 Immerse</div>
                      <div className="quote-job">CEO at 360 Immerse</div>
                    </div>
                  </div>
                </SwiperSlide>


                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'Cloudin labs work was totally oriented for customer satisfaction and took lot of pains to do a spectacular website with all features.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span>Green Bound</div>
                      <div className="quote-job">CEO at Green Bound</div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'Cloudin labs is truly a top notch web design services firm. In addition to being very technology savvy including search engine optimization, the design team also has a very good sense for the visual appearance.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span>Accel Top</div>
                      <div className="quote-job">CEO at Accel Top</div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="quote">
                    <p className="quote-p">'After having worked with multiple digital marketing and social media agencies, we have finally landed on a winner and hit a home run with Cloudin labs Marketing.'</p>
                    <div className="quote-info">
                      <div className="quote-name"><span className="pp"></span>Ragual</div>
                      <div className="quote-job">CEO at Sparsh</div>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>
          </div>

          {/* section 3 */}

          <div className="testi-content-imgs col-lg-3">
            <div className='testi-div-6 floating'>
              <img src="./Images/Testi/testi-6.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-7 floating'>
              <img src="./Images/Testi/testi-7.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-8 floating'>
              <img src="./Images/Testi/testi-8.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-9 floating'>
              <img src="./Images/Testi/testi-9.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-10 floating'>
              <img src="./Images/Testi/testi-10.jpg" alt='img' className="testi-img" />
            </div>
            <div className='testi-div-11 floating'>
              <img src="./Images/Testi/testi-11.jpg" alt='img' className="testi-img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;