import React, { useRef } from "react";
import "./CareerHome.css";
import Lottie from "react-lottie";
import WorkLottie from "../../../lotties/work-and-life-balance.json";
import CultureCarrier from "../CultureCareer/CultureCareer";
import CareerDescription from "../CareerDescription/CareerDescription";
import CareerFun from "../CareerFun/CareerFun";

function CarrierHome() {
  const CareerDesc = useRef(null);

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: WorkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "career-lottie-id",
      className: "career-lottie-id",
    },
  };

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <section className="career-home-section container">
        <div className="career-home-div">
          <div className="row career-home-row">
            <div className="col-md-5 col-12">
              <div className="career-home-content">
                <h4 className="career-home-h4">Working at Cloudin Labs</h4>
                <p className="career-home-p">
                  You just have to keep driving down the road. It's going to
                  bend and curve and you'll speed up and slow down, but the road
                  keeps going.
                </p>

                <button
                  className="btn career-home-button"
                  onClick={() => scrollDown(CareerDesc)}
                >
                  See open position
                </button>
              </div>
            </div>

            <div className="col-md-7 col-12">
              <div className="career-home-lottie-div">
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <CultureCarrier />

      <CareerFun />

      <section ref={CareerDesc}>
        <CareerDescription />
      </section>
    </>
  );
}

export default CarrierHome;
