import React from 'react';
import './ContactDelivery.css';

function ContactDelivery() {
  return ( 
    <>
      {/* <div className="contact-delivery-container container">
        <div className="contact-delivery-row row mx-lg-5">


          <div className="contact-delivery-1 col-sm-4 col-12">
            <div className="contact-delivery-ellipse">
              <h2 className="contact-delivery-h1">01.</h2>
            </div>
            <h3 className="contact-delivery-h3">A Quick Call</h3>
            <p className="contact-delivery-p">We are thrilled if you left us a message. Look forward to a call from our business team soon. </p>
          </div>

           <div className="contact-delivery-1 col-sm-4 col-12">
            <div className="contact-delivery-ellipse">
              <h2 className="contact-delivery-h1">02.</h2>
            </div>
            <h3 className="contact-delivery-h3">Understanding Needs</h3>
            <p className="contact-delivery-p">We take time over call or in person in trying to understand your needs and our role in it. </p>
          </div>

           <div className="contact-delivery-1 col-sm-4 col-12">
            <div className="contact-delivery-ellipse">
              <h2 className="contact-delivery-h1">03.</h2>
            </div>
            <h3 className="contact-delivery-h3">A Quick Call</h3>
            <p className="contact-delivery-p">Based on the discussion, we prepare a proposal, best suited for your project.</p>
          </div>

        </div>
      </div> */}
    </>
   );
}

export default ContactDelivery;