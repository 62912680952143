import React from "react";

function ProductInventory() {
  return (
    <>
      <div className="service-research-div container">
        <div className="service-research-content">
          <h4 className="service-research-head fw-bold mb-3 h5">
            INVENTORY : CHANGING WAREHOUSE LIFE INTO PLANS
          </h4>
          <h6 className="service-research-h6">
            Managing inventory has always been challenging, and trends in
            logistics management continue to make it harder every day. Cloudin
            Labs Inventory management system integrates seamlessly with Business
            that helps in control of your Orders, Inventory & Returns through a
            single, real-time dashboard view of your business- across channels &
            locations.
            <br />
            <br />
            Cloudin Labs easily track your inventory, know which items sell
            fast, which items expire soon. It combines with desktop software,
            barcode scanners, barcode labels and mobile devices for a single
            stockroom or multiple warehouses spread across the country.
          </h6>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">01.</h2>
                </div>
                <h4 className="service-research-h4">Order Management</h4>
                <p className="service-research-p">
                  Handles all your sales and purchase activities, manage invoice
                  and bills, and track payments.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">02.</h2>
                </div>
                <h4 className="service-research-h4">Warehouse & Stock room</h4>
                <p className="service-research-p">
                  Controls your stock in different warehouse or godowns
                  centrally from Cloudin Labs inventory.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">03.</h2>
                </div>
                <h4 className="service-research-h4">Barcode Scanning</h4>
                <p className="service-research-p">
                  Compatible with different barcode scanners to set up easily
                  barcode system and add SKUs, serial and batch numbers.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">04.</h2>
                </div>
                <h4 className="service-research-h4">Inventory Tracking</h4>
                <p className="service-research-p">
                  Keep the track of spare parts, saleable items and even expiry
                  dates with serial and batch tracking feature.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">05.</h2>
                </div>
                <h4 className="service-research-h4">Reports</h4>
                <p className="service-research-p">
                  Know your inventory aging, vendor payments, sales details and
                  inventory valuation from range of reports
                </p>
              </div>
            </div>
          </div>

          <div className="service-research-img-content">
            <div className="row">
              <div className="col-12">
                <div className="service-bg-img-div">
                  <img
                    src="./Images/Products/Inventory-bg.jpg"
                    className="service-bg-img"
                    alt='img' 
                  />
                  <div className="service-bg-div">
                    <h2 className="service-bg-h2">
                      Still Doing your Inventory Management in Excel?
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductInventory;
