import React from 'react';

function ItDeptService() {
  return ( 
    <>
       <section className='web-service-section container'>
        <div className='web-service-content-div px-md-0 px-sm-4 px-2'>
          <h1 className='web-service-content-1-h1'><span className="web-service-span"></span>WHAT WE DO AS A PART OF</h1>
          <h2 className='web-service-content-1-h2'>IT consulting exercise</h2>

          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Strategy Assessment</h4>
                <p className='service-research-p'>We assess your existing strategy-sources, 
platforms & architecture to evaluate if it 
is equipped to handle your rising 
business objectives.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Governance frameworks</h4>
                <p className='service-research-p'>Our consultants evaluate your existing 
governance framework and define rule 
and guidelines for robust and effective 
management of enterprise solutions.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Implementation Blueprint</h4>
                <p className='service-research-p'>It sequentially covers the set of activities 
& practices required to proceed from the 
‘Current State’ – ‘To Be’ State with clearly 
identified implementation milestones.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>Maturity</h4>
                <p className='service-research-p'>We develop an understanding of your 
present technology maturity and 
recommend processes & workshops for 
increased adoption and higher return on 
your technology investments.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Industry / Domain Specificity</h4>
                <p className='service-research-p'>Our techno-functional consultants quickly 
analyse the business scenario and 
recommend the best course of action</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>Performance</h4>
                <p className='service-research-p'>Our experts will analyze your company 
workflows, and track the performance to 
discover the loopholes. Then our developers 
will work towards eliminating the 
impending points.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
   );
}

export default ItDeptService;