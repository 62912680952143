import React from "react";
import "./Footer.css";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function Footer() {
  let navigate = useNavigate();
  return (
    <>
      <div className="footer-container container">
        <div className="row">
          <div className="col-sm col-12">
            <div className="footer-logo-content">
              <img
                src="./Images/cloudin-logo.svg"
                alt="Cloud-in logo"
                className="footer-logo"
              />
              <p className="footer-logo-p">Follow Us</p>
              <div className="profile-links">
                <a href="https://www.linkedin.com/company/cloudin-tech/mycompany/">
                  <i className="footer-link">
                    <AiFillLinkedin />
                  </i>
                </a>
                <a href="https://www.instagram.com/cloudin_labs/">
                  <i className="footer-link">
                    <AiFillInstagram />
                  </i>
                </a>
                <a href="https://www.facebook.com/CloudinTechlabs/">
                  <i className="footer-link">
                    <AiFillFacebook />
                  </i>
                </a>
                <a href="https://twitter.com/Cloudinlabs">
                  <i className="footer-link">
                    <AiFillTwitterSquare />
                  </i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm col-12">
            <div className="footer-address-1">
              <h1 className="footer-h5-1 hover-underline-animation-1">
                Head Office
              </h1>
              <p className="footer-p-1 mt-2">
                10th street, Shri sai Towers, <br />
                Axis Bank Building,
                <br />
                Coimbatore, Tamil Nadu,
                <br />
                India - 641012.
                <br />
                <br />
                Contact : 0422 - 4516093 <br />
                Email : info@cloudinlabs.com
                <br />
              </p>
            </div>
          </div>

          <div className="col-sm col-12">
            <div className="footer-address-2 mx-auto">
              <h1 className="footer-h5-1 hover-underline-animation-2">
                Head Quartres
              </h1>
              <p className="footer-p-1 mt-2">
                111 NE 1st St, <br />
                8th Floor Suite #88371, <br />
                Miami, <br />
                Florida 33132,
                <br />
                United States
                <br />
                <br />
                Contact : +1 5146120123 <br />
                Email : info@cloudinlabs.com <br />
              </p>
            </div>
          </div>

          <div className="col-sm col-12">
            <div className="footer-address-3">
              <h1 className="footer-h5-1 hover-underline-animation-3">
                Newzealand Office
              </h1>
              <p className="footer-p-1 mt-2">
                113D, Sturges Road, <br />
                Henderson, <br />
                Auckland, Newzealand. <br />
                <br />
                Contact : +64 02102920557 <br />
                Email : info@cloudinlabs.com <br />
              </p>
            </div>
          </div>

          <div className="col-sm col-12">
            <div className="footer-address-3">
              <h1 className="footer-h5-1 hover-underline-animation-3">
                Dubai Operations
              </h1>
              <p className="footer-p-1 mt-2">
                VR WorldWide <br />
                218, Room No :4
                <br />
                AWR Properties Building,
                <br />
                Dubai, UAE - 12323
                <br />
                <br />
                Contact : +0544417515
                <br />
                Email : VR@cloudinlabs.com
              </p>
            </div>
          </div>
        </div>

        <div className="footer-divider-line"></div>
        <div className="row">
          <div className="col-6">
            <p className="footer-copyright">
              &copy; Cloudin Labs 2022. All rights reserved
            </p>
          </div>
          <div className="col-6">
            <p className="footer-privacy">
              <span
                className="cursor-pointer"
                onClick={() => navigate("/privacy_policy")}
              >
                {" "}
                Privacy Policy{" "}
              </span>
              &nbsp; |&nbsp;{" "}
              <span
                className="cursor-pointer"
                onClick={() => navigate("/terms_and_condition")}
              >
                {" "}
                Terms & Conditions
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
