import React from "react";
import "./herosection.css";

export default function herosection() {
  return (
    <>
      <div className="container-fluid blockChain_herosection">
        <div className="row ">
          <div className="col-lg-3 d-md-block d-none ">
            <div className="blockChain_imgLeft">
              <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 text-center col-12 d-md-block d-flex justify-content-center">
            <div className="herotext">
              <img
                className="herologo mt-2"
                src="./Images/Blockchain_seo/logo.svg"
                alt="logo"
              />
              <h6 className="blockChain_herohead mt-4">
                <span className="">Blockchain Software Development </span><br></br><span className="">Company In Coimbatore.</span>
              </h6>
              <p className="">Delivering Best-In-Class Solutions</p>
            </div>
          </div>
          <div className="col-lg-3 d-md-block d-none">
            <div className=" blockChain_imgRight ">
              <img src="./Images/Blockchain_seo/blockchain.svg" alt="block" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='herosection'>
          <div className='herotext'>
            <img className='herologo mt-2' src='./Images/Blockchain_seo/logo.svg' alt='logo' />
            <h1 className='herohea mt-4'>Blockchain Software Development <br></br>Company In Coimbatore.</h1>

          
          </div>
          <div className='heroim'>
            <img src='./Images/Blockchain_seo/blockchain.svg' alt='block'/>
          </div>
        </div> */}
    </>
  );
}
