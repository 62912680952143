import React from 'react';
import './AboutMission.css';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/heart.json';

function AboutMission ()
{

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "about-mission-icon",
      className: "about-mission-icon-class"
    }
  };
  return (
    <>
      <div className="about-mission-container container">
        <div className="about-mission-1 row">
          <div className="about-mission-div col-12">
            <div className="about-mission-flex">
              <h1 className="about-mission-title">IDEA + DESIGN + DEVELOPMENT = </h1>
              <div className="about-mission-lottie-div">
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>

          <div className="about-mission-2 row">
            <div className="about-mission-div-1 col-sm-6 col-12">
              <h1 className='about-mission-content-1-h1'><span className="about-mission-span"></span>02</h1>
              <h2 className='about-mission-content-1-h2'>Our Vision</h2>
              <div className='about-mission-inner'>
                <p className='about-mission-p'>
                  To become a renowned brand as one of the top-most
                  preferred and globally respected corporation by
                  finding ways to provide solutions to our clients with excellent
                  value and choice.
                </p>
              </div>
            </div>


            <div className="about-mission-div-1 col-sm-6 col-12">
              <h1 className='about-mission-content-1-h1'><span className="about-mission-span"></span>03</h1>
              <h2 className='about-mission-content-1-h2'>Our Mission</h2>
              <div className='about-mission-inner'>
                <p className='about-mission-p'>
                  To provide the cost effective, top-quality, transparent,
                  highly fragmentated professional services to
                  our esteemed clients worldwide with
                  uncompromising deadlines and commitment.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMission;