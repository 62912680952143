import React from 'react';
import '../App.css';

import AboutAbout from '../Components/AboutContainer/AboutAbout/AboutAbout';
import AboutBegin from '../Components/AboutContainer/AboutBegin/AboutBegin';
import AboutClient from '../Components/AboutContainer/AboutClient/AboutClient';
import AboutHome from '../Components/AboutContainer/AboutHome/AboutHome';
import AboutMission from '../Components/AboutContainer/AboutMission/AboutMission';
import AboutProcess from '../Components/AboutContainer/AboutProcess/AboutProcess';
import AboutTesti from '../Components/AboutContainer/AboutTestimonial/AboutTesti';
import Footer from '../Footer/Footer';
import Loading from '../Loading/loading';
import Navbar from '../Navbar/Navbar';
import SEO from '../seo';

function AboutApp() {

  const canonicalUrl = window.location.href;
  
 

  return ( 
    <>

      <SEO
        title="Growing Your Business with Strategic Solutions."
        description=" Innovating the Future of Technology. A cutting-edge tech company driving revolutionary advancements in business solutions. "
        url={canonicalUrl}
        status = "no index"
      />
      <Loading/>
      <Navbar/>
      <AboutHome />
      <AboutAbout />
      <AboutClient />
      <AboutMission />
      <AboutBegin />
      <AboutProcess />
      <AboutTesti />
      <Footer/>
    </>
   );
}

export default AboutApp;