import React from 'react';
import './WebDeptTech.css';


function WebDeptTech() {
  return ( 
    <>
      <section className='web-tech-section container my-4'>
        <div className='web-tech-content-div'>
          <div className='row'>
            <div className='col-12'>
              <div className='web-tech-div-1 d-md-flex'>
                <div className='col-md-5 col-12'>
                  <h1 className='web-tech-h2 h2 lh-base fw-bold'>Our Technology<br/> Expertise</h1>  
                </div>
                <div className='col-md-7 col-12'>
                  <p className='web-tech-p'>Developing powerful websites is only possible with the right technology. Our professional corporate website development engineers will combine multiple technologies to achieve the anticipated result and deliver a modern, fast, and easily maintainable web solution.</p>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/javascript.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Javascript</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/angularjs.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Angular</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/react.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>React</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/html.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>HTML5</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/css3.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>CSS3</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/net.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>.NET</h6>
              </div>
            </div>

            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/php.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>PHP</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/mongodb.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>MongoDB</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/laravel.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Laravel</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/bootstrap.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Bootstrap</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/wordpress.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Wordpress</h6>
              </div>
            </div>
            <div className='col-md-2 col-sm-4 col-6'>
              <div className='web-tech-card card'>
                <div className='web-tech-img-div'>
                  <img src='./Images/Services/web dev/ai.svg' className='web-tech-img' alt='img' /> 
                </div>
                  <h6 className='web-tech-h6'>Artificial Intelligence</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
   );
}

export default WebDeptTech;