import React from "react";
import "../App.css";

// Home container

import About from "../Components/HomeContainers/About/About";
import Client from "../Components/HomeContainers/Client/Client";
import Home from "../Components/HomeContainers/Home/Home";

import Services from "../Components/HomeContainers/Services/Services";
import Product from "../Components/HomeContainers/Products/Product";
import Portfolio from "../Components/HomeContainers/Portfolio/Portfolio";
import Testimonials from "../Components/HomeContainers/Testimonials/Testimonial";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Review from "../Components/HomeContainers/Review/Review";
import Loading from "../Loading/loading";
import Blockchain from "../Blockchain/Blockchain";
import SEO from "../seo";
import { Helmet } from 'react-helmet';


function HomeApp() {

  const canonicalUrl = window.location.href;
	
  return (
    <div className="App">
      <SEO
        title="Best Software Development Company - Cloudinlabs"
        description=" "
        url={canonicalUrl}
        status="index"
      />
      
       {/* <Helmet>
        <title>Home Page</title>
        <meta name="description" content="Welcome to the Home page." />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet> */}
      <Loading />
      <Navbar />
      <Home />
      <About />
      <Client />
      <Services />
      <Product />
      <Portfolio />
      <Review />
      <Testimonials />
      <Blockchain />
      <Footer />
    </div>
  );
}

export default HomeApp;
