import React from 'react';
import './AboutProcess.css';

function AboutProcess ()
{
  return (
    <>
      <div className="about-process-container container-fluid">
        <div className="about-process-row row">
          <div className="about-process-row-2">
            <img src="./Images/About/Path 1.svg" className="about-process-bg-img" alt="path" />
            <div>
              <div className="about-process-content-1">
                <h1 className='about-process-content-1-h1'><span className="about-process-span"></span>04</h1>
                <h2 className='about-process-content-1-h2'>The Process We Follow</h2>
                <p className='about-process-content-1-p'>As a academy of business, we are going through the development cycle.
                </p>
              </div>
            </div>


            <div className="about-process-flex-1">
              <div className="flex-1">
                <h6 className="flex-1-h6">Business Idea</h6>
                <p className="flex-1-p">What you want your business to be.</p>
                <img src='./Images/Testi/testi-1.jpg' className='flex-1-img' alt='img' />
              </div>

              <div className="new-flex-1">
                <p className="flex-1-p">What you want your business to be.</p>
                <h6 className="flex-1-h6">Business Idea</h6>
                <img src='./Images/Testi/testi-1.jpg' className='flex-1-img' alt='img' />
              </div>
            </div>

            {/* flex-2 */}

            <div className="about-process-flex-2">
              <div className="flex-2">
                <img src='./Images/Testi/testi-4.jpg' className='flex-2-img' alt='img' />
                <h6 className="flex-1-h6">Planning</h6>
                <p className="flex-1-p">A business plan helps formalize your idea.</p>
              </div>
            </div>

            {/* flex-3 */}

            <div className="about-process-flex-3">
              <div className="flex-3">
                <h6 className="flex-1-h6">Develop</h6>
                <p className="flex-1-p">Ideation, prototype, costing of your product.</p>
                <img src='./Images/Testi/testi-5.jpg' className='flex-2-img' alt='img' />
              </div>

              <div className="new-flex-3">
                <p className="flex-1-p">Ideation, prototype, costing of your product.</p>
                <h6 className="flex-1-h6">Develop</h6>
                <img src='./Images/Testi/testi-5.jpg' className='flex-2-img' alt='img' />
              </div>
            </div>

            {/*  flex-4 */}

            <div className="about-process-flex-4">
              <div className="flex-4">
                <div className="flex-font">
                  <h6 className="flex-4-h6">Business Structure</h6>
                  <p className="flex-4-p">Key parts of your business.</p>
                </div>
                <img src='./Images/Testi/testi-6.jpg' className='flex-4-img' alt='img' />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default AboutProcess;