import React from 'react';
import './Portfolio.css';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import WorkLottie from '../../../lotties/arrow-lottie.json';

function Portfolio ()
{

    const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };
 let navigate = useNavigate();

  return (
  //   <>
  //     <div className="portfolio-container container ">
  //       {/* section 1 */}
  //       <div className="row">
  //        {/* <div className="portfolio-section-1 col-7">
  //           <div className="portfolio-content-1 ">
  //             <h1 className='portfolio-content-1-h1'><span className="service-span"></span>04</h1>
  //             <h2 className='portfolio-content-1-h2'>Our Portfolios</h2>
  //             <h6 className='portfolio-content-1-h6'>VIEW OUR RECENT CASE STUDIES</h6>
  //           </div>
  //         </div>
  //         <div className='product-content-div-2 col-5'>
  //           <p  className='product-content-div-p' onClick={() => navigate('/Work')}>See all our Portfolios <div className='arrow-lottie-div'><Lottie options={lottieAnimation}/></div></p>
  // </div> */}
  //         <div className="product-section-1 col-8">
  //           {/* content-1 */}
  //           <div className="product-content-1 ">
  //             <h1 className='product-content-1-h1'><span className="product-span"></span>03</h1>
  //             <h2 className='product-content-1-h2'>Our Portfolios</h2>
  //             <h6 className='product-content-1-h6'>VIEW OUR RECENT CASE STUDIES</h6>
  //           </div>
  //           </div>
  //           <div className='product-content-div-2 col-4'>
  //              <p  className='product-content-div-p' onClick={() => navigate('/Work')}>See all our Portfolios <div className='arrow-lottie-div'><Lottie options={lottieAnimation}/></div></p>
  //           </div>

  

  //         {/* section 2 */}

  //         <div className='portfolio-content-2 col-12'>
  //           <div className='portfolio-content-2-image-wrap'>
  //             <div className='portfolio-2-img-div'>
  //               <a href="#">
  //               <img src='./Images/Portfolio/port-1.jpg' alt='img' className='portfolio-2-img' />
  //               </a>
  //               <h1 className='portfolio-content-2-h1'>Projects</h1>
  //             </div>

  //             <div className='portfolio-2-img-div '>
  //               <a href="#">
  //               <img src='./Images/Portfolio/port-2.jpg' alt='img' className='portfolio-2-img'/>
  //               </a>
  //               <h1 className='portfolio-content-2-h1'>Projects</h1>
  //             </div>

  //             <div className='portfolio-2-img-div'>
  //               <a href="#">
  //               <img src='./Images/Portfolio/port-3.jpg' alt='img' className='portfolio-2-img'/>
  //               </a>
  //               <h1 className='portfolio-content-2-h1'>Projects</h1>
  //             </div>

  //             <div className='portfolio-2-img-div'>
  //               <a href="#">
  //               <img src='./Images/Portfolio/port-4.jpg' alt='img' className='portfolio-2-img'/>
  //               </a>
  //               <h1 className='portfolio-content-2-h1'>Projects</h1>
  //             </div>

  //             <div className='portfolio-2-img-div'>
  //               <a href="#">
  //               <img src='./Images/Portfolio/port-5.jpg' alt='img' className='portfolio-2-img' />
  //               </a>
  //               <h1 className='portfolio-content-2-h1'>Projects</h1>
  //             </div>

  //              <div className='portfolio-2-img-div'>
  //               <a href="#">
  //               <img src='./Images/Portfolio/port-6.jpg' alt='img' className='portfolio-2-img'  />
  //               </a>
  //               <h1 className='portfolio-content-2-h1'>Projects</h1>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </> 
  <div></div>
  );
}

export default Portfolio;