import React from 'react';

function MobileDeptService() {
  return ( 
    <>
       <section className='web-service-section container'>
        <div className='web-service-content-div px-md-0 px-sm-4 px-2'>
          <h1 className='web-service-content-1-h1'><span className="web-service-span"></span>OUR FULL-STACK</h1>
          <h2 className='web-service-content-1-h2'>Full-cycle mobility solutions</h2>

          <div className='row'>
            
            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>01.</h2>
                </div>
                <h4 className='service-research-h4'>Custom Mobile Application Development</h4>
                <p className='service-research-p'>Creating feature-packed customized mobile application with out-of-box custom software development approach.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>02.</h2>
                </div>
                <h4 className='service-research-h4'>Technology Consulting</h4>
                <p className='service-research-p'>Implement the most appropriate technology stack and well-thought-out system architecture for your mobile app.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>03.</h2>
                </div>
                <h4 className='service-research-h4'>Digital Transformation</h4>
                <p className='service-research-p'>Transform digitally with advanced technologies such as AI, cloud computing, the IoT, and more</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>04.</h2>
                </div>
                <h4 className='service-research-h4'>App Integration</h4>
                <p className='service-research-p'>Provides the latest technology integration, payment gateway integration, and API integration in your existing mobile app.</p>
              </div>
            </div>

             <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>05.</h2>
                </div>
                <h4 className='service-research-h4'>Upgradations & Migration</h4>
                <p className='service-research-p'>Provides holistic approach to upgrade your existing mobile app to the latest technology or migrate your app to a different operating system or platform.</p>
              </div>
            </div>

            <div className='col-md-4 col-sm-6 col-12'>
              <div className='service-research-div-1'>
                <div className='service-research-number-div'>
                  <h2 className='service-research-h2'>06.</h2>
                </div>
                <h4 className='service-research-h4'>QA & Testing Services</h4>
                <p className='service-research-p'>Puts quality analysts in the loop so that you can make sure your mobile app runs seamlessly across all devices and OS.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
   );
}

export default MobileDeptService;