import React from "react";
import "../App.css";
import CareerHire from "../Components/CarrierContainer/CareerHire/CareerHire";
import CareerHome from "../Components/CarrierContainer/CareerHome/CareerHome";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import NewNavbar from "../NewNavbar/NewNavbar";
import SEO from "../seo";

function CareerApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
      <SEO
        title="Careers for CloudInlabs : Work with a Dynamic Group"
        description=" Looking for fascinating career opportunities in the CloudInlabs? Talented people are being sought after by Cloudin Labs to join our vibrant team."
        url={canonicalUrl}
        status="index"
      />
      <Loading />
      <NewNavbar />
      <CareerHome />
      <CareerHire />
      <Footer />
    </>
  );
}

export default CareerApp;
