import React from "react";
import WebDeptAbout from "../Components/DeptContainer/WebDept/WebDeptAbout/WebDeptAbout";
import WebDeptDiscuss from "../Components/DeptContainer/WebDept/WebDeptDiscuss/WebDeptDiscuss";
import WebDeptHome from "../Components/DeptContainer/WebDept/WebDeptHome/WebDeptHome";
import WebDeptService from "../Components/DeptContainer/WebDept/WebDeptService/WebDeptService";
import WebDeptTech from "../Components/DeptContainer/WebDept/WebDeptTech/WebDeptTech";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/Navbar";
import SEO from "../seo";

function WebDeptApp() {
  const canonicalUrl = window.location.href;

  return (
    <>
      <SEO
        title=" Web development professionals at Cloudin Labs"
        description=" Cloudin Labs offers excellent web development services. Explore innovative methods to improve your websites visibility.Unlock the full potential of your website."
        url={canonicalUrl}
        status="index"
      />
      <Loading />
      <Navbar />
      <WebDeptHome />
      <WebDeptAbout />
      <WebDeptService />
      <WebDeptTech />
      <WebDeptDiscuss />
      <Footer />
    </>
  );
}

export default WebDeptApp;
