import React from 'react';
import Lottie from 'react-lottie';
import WorkLottie from '../../../../lotties/ai-lottie-1.json';
import {ImArrowRight2} from 'react-icons/im'
import './AiDeptTech.css';


function AiDeptTech ()
{
  
   const lottieAnimation = {
      loop: true,
      autoplay: true,
      animationData: WorkLottie,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
         id:"career-lottie-id" ,
        className:"career-lottie-id"
      }
  };
  return ( 
    <>
      <section className='ai-tech-section container'>
        <div className='ai-tech-content-div'>
          <div className='row'>
            <div className='col-md-6 col-12'>
              <div className='ai-tech-lottie-div'>
                <Lottie options={lottieAnimation} />
              </div>
            </div>

            <div className='col-md-6 col-12'>
              <div className='ai-tech-div'>
                <h1 className='ai-tech-h1'>The AI adoption with unleashing benefits</h1>

                <ul className='ai-tech-ul'>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Integrates with existing AI infrastructure</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Deliver intelligent and automated solutions</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Unlock doors to new possibilities</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Build complex use-cases easily</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Boost business productivity</p>
                  </li>
                  <li className='ai-tech-li'>
                    <ImArrowRight2 className='ai-tech-icon'/>
                    <p className='ai-tech-p'>Enhances and accelerates AI journey</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
   );
}

export default AiDeptTech;