import React from 'react';
import TCHome from '../Components/TermsAndConditions/TC-Home/TCHome';
import TcConditions from '../Components/TermsAndConditions/TcConditions/TcCondition';
import Footer from '../Footer/Footer';
import Loading from '../Loading/loading';
import Navbar from '../Navbar/Navbar';

function TcApp() {
    return ( 
        <>
            <Loading/>
            <Navbar/>
            <TCHome />
            <TcConditions />
            <Footer/>
        </>
     );
}

export default TcApp;