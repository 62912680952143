import React from 'react';
import { useNavigate } from "react-router-dom";


function AiDeptDiscuss ()
{
  let navigate = useNavigate();
  return ( 
    <>
      <div className="work-begin-container container-fluid mt-5">
          <div className="work-begin-section-2 row px-sm-5 px-0">
            <div className="col-md-8 col-sm-8 col-12 py-sm-5 py-4 ps-md-5 ps-4 work-begin-content-2">
            <h1 className="web-discuss-h1 text-white fw-bold h2 ">Let's Discuss Your Project</h1>
            <p className='web-discuss-p text-white'>Get free consultation and let us know your project idea to turn it into an amazing digital product.</p>
            </div>
            <div className="col-md-4 col-sm-4 col-12 d-sm-flex align-items-center pb-sm-0 pb-4 ps-sm-5 ps-4 web-discuss-content-2">
              <button className="web-discuss-btn" onClick={() => navigate('/Contact')}>Get in touch</button>
            </div>
          </div>
        </div>
    </>
   );
}

export default AiDeptDiscuss;