import React from "react";
import HerosectionAdv from "../Components/BlockchainAdvantage/HerosectionAdv/herosectionAdv";
import ContentAdv from "../Components/BlockchainAdvantage/ContentAdv/contentAdv";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import NewNavbar from '../NewNavbar/NewNavbar';
import SEO from '../seo';

import "../App.css";

function BlockchainAdv() {

  const canonicalUrl = window.location.href;

  return (
    <>
    <SEO
        title=" Advantages of Using Blockchain Technology - Cloudinlabs"
        description="Discover the Advantages of Using Blockchain Technology and explore its future applications. Gain blockchain's transparency, security, and disruptive power. "
        url={canonicalUrl}
        status = "index"
      />
      <Loading />
      <NewNavbar />
      <div className="Blockchainpage">
        <header className="App-header">
          <HerosectionAdv />
          <ContentAdv />
        </header>
      </div>
      <Footer />
    </>
  );
}

export default BlockchainAdv;
