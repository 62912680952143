import React from 'react';
import ScrollSpy from "react-ui-scrollspy";
import ProductInventory from '../ProductInventory/ProductInventory';
import { Link } from "react-scroll";
import './ProductScroll.css'
import ProductCfin from '../ProductCfin/ProductCfin';
import ProductCt from '../ProductCt/ProductCt';



function ProductScroll ()
{
  


  return ( 

    <>
      <section className='service-scroll-section container'>
        <ul className='product-nav-ul'>
          <Link to="Inventory" smooth={true} offset={-160} duration={0} data-to-scrollspy-id="Inventory" className='service-nav-li'>
            <h6>Inventory</h6>
            <div className='service-nav-line'></div>
          </Link>


          <Link to="C-fin" smooth={true} offset={-160} duration={0} data-to-scrollspy-id="C-fin" className='service-nav-li'>
            <h6 >Cfin</h6>
            <div className='service-nav-line'></div>
          </Link>
         
          <Link to="Cryptotrack" smooth={true} offset={-160} duration={0} data-to-scrollspy-id="Cryptotrack" className='service-nav-li'>
            <h6 >Cryptotrack</h6>
            <div className='service-nav-line'></div>
          </Link>
        </ul>





        <ScrollSpy className='service-scroll-div'>
          
        <div id='Inventory'><ProductInventory /></div>
        
        <div id='C-fin'><ProductCfin/></div>

        <div id='Cryptotrack'><ProductCt/></div>
        
        </ScrollSpy>
      
      </section>
    </>
   );
}

export default ProductScroll;