import React from 'react';
import '../../WorkContainer/WorkBegin/WorkBegin.css';
import { useNavigate } from "react-router-dom";

function ServiceBegin ()
{
  
  let navigate = useNavigate();

  return ( 
    <>
       <div className="work-begin-container container-fluid mt-5">
        <div className="row work-begin-section-1">
          <div className="work-begin-content-1 col-12">
            <h6 className="work-begin-h6">Let's begin</h6>
            <div className='work-begin-line'></div>
          </div>
          
          <div className="work-begin-section-2 row">
            <div className="col-md-8 col-sm-8 col-12 work-begin-content-2">
                <h1 className="work-begin-h1">Do you care about quality? We've got everything to launch and grow your business</h1>
            </div>
            <div className="col-md-4  col-sm-4 col-12 work-begin-content-2">
              <button className="work-begin-btn" onClick={() => navigate('/Contact')}>Get in touch</button>
            </div>
          </div>
        </div>
      </div>
    </>
   );
}

export default ServiceBegin;