import React from "react";
import HerosectionLearn from "../Components/BlockchainLearn/HerosectionLearn/herosectionlearn";
import ContentLearn from "../Components/BlockchainLearn/ContentLearn/contentlearn";
import Footer from "../Footer/Footer";
import Loading from "../Loading/loading";
import NewNavbar from "../NewNavbar/NewNavbar";
import "../App.css";
import SEO from '../seo';

function BlockchainLearn() {

  const canonicalUrl = window.location.href;

  return (
    <>
    <SEO
        title="How to learn blockchain technology - An Expert Guidance"
        description=" Discover the best resources on how to learn blockchain technology. Master the fundamentals and unlock the potential of this revolutionary technology."
        url={canonicalUrl}
        status = "no index"
      />
      <Loading />
      <NewNavbar />
      <div className="Blockchainpage">
        <header className="App-header">
          <HerosectionLearn />
          <ContentLearn />
        </header>
      </div>
      <Footer />
    </>
  );
}

export default BlockchainLearn;
