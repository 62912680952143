import React from "react";
import "./ServiceStrategy.css";

function ServiceStrategy() {
  return (
    <>
      <div className="service-research-div container">
        <div className="service-research-content">
          {/*<h6 className='service-research-h6'>Drive insight and mapping a product roadmap enabling growth in certain and uncertain times.</h6>*/}
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">01.</h2>
                </div>
                <h4 className="service-research-h4">Design Thinking</h4>
                <p className="service-research-p">
                  Our thinking is both an ideology and a process that seeks to
                  solve complex problems in a user-centric way. It focuses on
                  achieving practical results and solutions
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">02.</h2>
                </div>
                <h4 className="service-research-h4">Data Visualization</h4>
                <p className="service-research-p">
                  We create visual representations of data. These
                  representations clearly communicate insights from data through
                  charts and graphs. In terms of business intelligence (BI),
                  these visualizations help users make better data-based
                  decisions.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">03.</h2>
                </div>
                <h4 className="service-research-h4">Product Roadmap</h4>
                <p className="service-research-p">
                  A high-level visual that maps out the vision, direction and
                  evolution of your product over time.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">04.</h2>
                </div>
                <h4 className="service-research-h4">Growth & Service</h4>
                <p className="service-research-p">
                  We are driven by five core pillars, delivering sustainable
                  growth & ensuring acknowledge-transfer from us to your team.We
                  tailor this approach to your goals & team structure, ziplining
                  between an advisor or an extra pair of hands for execution
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-12">
              <div className="service-research-div-1">
                <div className="service-research-number-div">
                  <h2 className="service-research-h2">05.</h2>
                </div>
                <h4 className="service-research-h4">User Engagement</h4>
                <p className="service-research-p">
                  We measure user engagement is essential to growing your
                  publishing business. By tracking and analysing metrics that
                  indicate user engagement levels, you will understand how
                  valuable your content, product offerings, and services are to
                  users.
                </p>
              </div>
            </div>
          </div>

          <div className="service-research-img-content">
            <div className="row">
              <div className="col-12">
                <div className="service-bg-img-div">
                  <img
                    src="./Images/Services/strategy-bg.jpg"
                    className="service-bg-img"
                    alt='img' 
                  />
                  <div className="service-bg-div">
                    <h2 className="service-bg-h2">
                      Strategy is not the consequence of planning, but the
                      opposite: its starting point.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceStrategy;
