import React from "react";
import Lottie from "react-lottie";
import WorkLottie from "../../../../lotties/it-lottie-1.json";
import { useNavigate } from "react-router-dom";

function ItDeptAbout() {
  let navigate = useNavigate();

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: WorkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "career-lottie-id",
      className: "career-lottie-id",
    },
  };

  return (
    <>
      <section className="web-about-section container my-md-5 my-4">
        <div className="web-about-content-div px-md-0 px-sm-4 px-2">
          <div className="row">
            <div className="col-md-7 col-12">
              <h1 className="web-about-content-1-h1">
                <span className="web-about-span"></span>ENHANCE THE RIGHT
                TECHNOLOGY
              </h1>
              <h2 className="web-about-content-1-h2">
                Consulting solutions suitable for your key business domains our
                driving vision
              </h2>
              <p className="web-about-content-1-p">
                Faster time to market, delightful customer experience and
                continuous evolution in the midst of uncertainties are the most
                pressing concerns of disruptive product companies. Technology
                Consulting can help businesses in making the best use of
                technology to achieve their business objectives through
                professional guidance and advice.
                <br />
                <br />
                Cloudin Labs offers IT consulting services that can help
                companies in improving software architecture, creating a
                tech-driven digital strategy, and finally improving operations
                by optimizing their software portfolio. Cloudin Labs
                professionals around the World Help Clients Resolve their Most
                Critical Information and Technology Challenges. Read on. Cloudin
                Labs brings to the table unparalleled domain expertise in
                specific industries/processes, coupled with best-in-class
                technology skills and proven assets that can accelerate the
                value creation for clients for our Operate Services.
              </p>

              {/*<button className='web-content-2-btn' onClick={() => navigate('/Contact')}>Hire Now</button>*/}
            </div>

            <div className="col-md-5 col-12">
              <div className="ml-about-lottie mt-md-5">
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ItDeptAbout;
