import React from "react";
import "./CareerHire.css";

function CareerHire ()
{
  return (
    <>
      <section className="career-hire-section container-fluid">
        <div className="career-hire-content">
          <h1 className="career-hire-h1">Our Hiring Process</h1>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="card career-hire-card">
                <div className="career-hire-inner-div">
                  <div className="career-hire-img-div">
                    <img
                      src="./Images/Career/approved (1).png"
                      className="career-hire-img"
                      alt='img'
                    />
                  </div>
                  <h2 className="career-hire-h2">1</h2>
                </div>
                <h3 className="career-hire-h3">CV Submission</h3>
                <p className="career-hire-p">
                  Submit your cv or resume through our online portal, if you
                  meet our requirements.{" "}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="card career-hire-card">
                <div className="career-hire-inner-div">
                  <div className="career-hire-img-div">
                    <img
                      src="./Images/Career/phone (2).png"
                      className="career-hire-img"
                      alt='img'
                    />
                  </div>
                  <h2 className="career-hire-h2">2</h2>
                </div>
                <h3 className="career-hire-h3">Phone Screening</h3>
                <p className="career-hire-p">
                  After looking at your cv you will be invited for a telephone
                  interview at a time of your choosing.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="card career-hire-card">
                <div className="career-hire-inner-div">
                  <div className="career-hire-img-div">
                    <img
                      src="./Images/Career/curriculum.png"
                      className="career-hire-img"
                      alt='img'
                    />
                  </div>
                  <h2 className="career-hire-h2">3</h2>
                </div>
                <h3 className="career-hire-h3">Skill Assessment</h3>
                <p className="career-hire-p">
                  You will be invited at our head office to take a skills test
                  and the knowledge assessment.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="card career-hire-card">
                <div className="career-hire-inner-div">
                  <div className="career-hire-img-div">
                    <img
                      src="./Images/Career/interview.png"
                      className="career-hire-img"
                      alt='img'
                    />
                  </div>
                  <h2 className="career-hire-h2">4</h2>
                </div>
                <h3 className="career-hire-h3">Final Interview</h3>
                <p className="career-hire-p">
                  If you pass all stages we will invite you for a final
                  interview with Hiring team & HR executive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CareerHire;
