import React from "react";
import "./WebDeptAbout.css";
import Lottie from "react-lottie";
import WorkLottie from "../../../../lotties/web-development-lottie.json";
import { useNavigate } from "react-router-dom";

function WebDeptAbout() {
  let navigate = useNavigate();

  const lottieAnimation = {
    loop: true,
    autoplay: true,
    animationData: WorkLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      id: "career-lottie-id",
      className: "career-lottie-id",
    },
  };

  return (
    <>
      <section className="web-about-section container my-md-5 my-4">
        <div className="web-about-content-div px-md-0 px-sm-4 px-2">
          <div className="row">
            <div className="col-md-7 col-12">
              <h1 className="web-about-content-1-h1">
                <span className="web-about-span"></span>END-TO-END
              </h1>
              <h2 className="web-about-content-1-h2">
                Ground-up bespoke web development solutions our Custom Solutions
              </h2>
              <p className="web-about-content-1-p">
                Modern web applications nowadays ought to provide a
                native-app-like user experience on the web. The richer and
                snappier your app is, the better edge you have in this
                competitive market. And as an expert web development company,
                Cloudin Labs provides web application development service that
                makes your web solutions superior.
                <br />
                <br />
                We here at Cloudin Labs help you build tailored web application
                for your specific business needs. We design contextual UX/UI for
                better usability, select the appropriate architecture for best
                performance and write custom code to incorporate your complex
                and unique business logic in your web application.
              </p>

              {/*<button className='web-content-2-btn' onClick={() => navigate('/Contact')}>Hire Now</button>*/}
            </div>

            <div className="col-md-5 col-12">
              <div className="web-about-lottie">
                <Lottie options={lottieAnimation} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WebDeptAbout;
